import { createSlice } from '@reduxjs/toolkit';

import { covertToJSON } from '../../utils/stateToJson';
import {
  createCloth,
  deleteCloth,
  getAllCloths,
  getClothDetails,
  updateClothDetails,
} from '../api/cloth';

export const clothSlice = createSlice({
  name: 'cloth',
  initialState: {
    loading: false,
    error: {},
    // staff
    cloths: {},
  },
  reducers: {
    clearStaffError: (state) => {
      state.error = true;
    },
  },
  extraReducers: {
    [createCloth.pending]: (state) => {
      state.loading = true;
    },
    [createCloth.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.cloths;
      const newClothList = jsonState?.cloths;

      const modifiedClothList = {
        ...jsonState,
        cloths: Array.isArray(newClothList) ? [action.payload, ...newClothList] : [action.payload],
      };
      state.loading = false;
      state.cloths = modifiedClothList;
      state.error = {};
    },
    [createCloth.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [getAllCloths.pending]: (state) => {
      state.loading = true;
    },
    [getAllCloths.fulfilled]: (state, action) => {
      state.loading = false;
      state.cloths = action.payload;
      state.error = {};
    },
    [getAllCloths.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [getClothDetails.pending]: (state) => {
      state.loading = true;
    },
    [getClothDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.bookingDetails = action.payload;
      state.error = {};
    },
    [getClothDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [updateClothDetails.pending]: (state) => {
      state.loading = true;
    },
    [updateClothDetails.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.cloths;
      const modifiedClothList = {
        ...jsonState,
        cloths: jsonState.cloths?.map((i) => (i._id === action.payload._id ? action?.payload : i)),
      };

      state.loading = false;
      state.cloths = modifiedClothList;
      state.error = {};
    },
    [updateClothDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [deleteCloth.pending]: (state) => {
      state.loading = true;
    },
    [deleteCloth.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.cloths;
      const modifiedClothList = {
        ...jsonState,
        cloths: jsonState.cloths?.filter((cloth) => cloth._id !== action.payload),
      };
      state.loading = false;
      state.cloths = modifiedClothList;
      state.error = {};
    },
    [deleteCloth.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
  },
});
export const { clearStaffError } = clothSlice.actions;

export default clothSlice.reducer;
