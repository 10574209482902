import { createSlice } from '@reduxjs/toolkit';

import { covertToJSON } from '../../utils/stateToJson';
import { createCustomer, deleteCustomer, getAllCustomers, getCustomerDetails, updateCustomerDetails } from '../api/customer';

export const customerSlice = createSlice({
  name: 'customer',
  initialState: {
    loading: false,
    error: {},
    // staff
    customers: {},
    customerDetails: {},
  },
  reducers: {
    clearStaffError: (state) => {
      state.error = true;
    },
    clearCustomerDetails: (state) => {
      state.customerDetails = {}
    }
  },
  extraReducers: {
    [createCustomer.pending]: (state) => {
      state.loading = true;
    },
    [createCustomer.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.customers;
      const newCustomerList = jsonState?.customers;

      const modifiedCustomerList = {
        ...jsonState,
        customers: Array.isArray(newCustomerList)
          ? [action.payload, ...newCustomerList]
          : [action.payload],
      };
      state.loading = false;
      state.customers = modifiedCustomerList;
      state.error = {};
    },
    [createCustomer.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [getAllCustomers.pending]: (state) => {
      state.loading = true;
    },
    [getAllCustomers.fulfilled]: (state, action) => {
      state.loading = false;
      state.customers = action.payload;
      state.error = {};
    },
    [getAllCustomers.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [getCustomerDetails.pending]: (state) => {
      state.loading = true;
    },
    [getCustomerDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.customerDetails = action.payload;
      state.error = {};
    },
    [getCustomerDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [updateCustomerDetails.pending]: (state) => {
      state.loading = true;
    },
    [updateCustomerDetails.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.customers;
      const modifiedCustomerList = {
        ...jsonState,
        customers: jsonState.customers?.map((i) =>
          i._id === action.payload._id ? action?.payload : i
        ),
      };

      state.loading = false;
      state.customers = modifiedCustomerList;
      state.error = {};
    },
    [updateCustomerDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [deleteCustomer.pending]: (state) => {
      state.loading = true;
    },
    [deleteCustomer.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.customers;
      const modifiedCustomerList = {
        ...jsonState,
        customers: jsonState.customers?.filter((customer) => customer._id !== action.payload),
      };
      state.loading = false;
      state.customers = modifiedCustomerList;
      state.error = {};
    },
    [deleteCustomer.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
  },
});
export const { clearStaffError, clearCustomerDetails } = customerSlice.actions;

export default customerSlice.reducer;
