import { createSlice } from '@reduxjs/toolkit';

import { covertToJSON } from '../../utils/stateToJson';
import {
  createShop,
  deleteShop,
  getAllShops,
  getShopDetails,
  updateShopDetails,
} from '../api/shop';

export const shopSlice = createSlice({
  name: 'shop',
  initialState: {
    loading: false,
    error: {},
    // staff
    shops: {},
  },
  reducers: {
    clearStaffError: (state) => {
      state.error = true;
    },
  },
  extraReducers: {
    [createShop.pending]: (state) => {
      state.loading = true;
    },
    [createShop.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.shops;
      const newShopList = jsonState?.shops;

      const modifiedShopList = {
        ...jsonState,
        shops: Array.isArray(newShopList) ? [action.payload, ...newShopList] : [action.payload],
      };
      state.loading = false;
      state.shops = modifiedShopList;
      state.error = {};
    },
    [createShop.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [getAllShops.pending]: (state) => {
      state.loading = true;
    },
    [getAllShops.fulfilled]: (state, action) => {
      state.loading = false;
      state.shops = action.payload;
      state.error = {};
    },
    [getAllShops.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [getShopDetails.pending]: (state) => {
      state.loading = true;
    },
    [getShopDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.bookingDetails = action.payload;
      state.error = {};
    },
    [getShopDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [updateShopDetails.pending]: (state) => {
      state.loading = true;
    },
    [updateShopDetails.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.shops;
      const modifiedShopList = {
        ...jsonState,
        shops: jsonState.shops?.map((i) => (i._id === action.payload._id ? action?.payload : i)),
      };

      state.loading = false;
      state.shops = modifiedShopList;
      state.error = {};
    },
    [updateShopDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [deleteShop.pending]: (state) => {
      state.loading = true;
    },
    [deleteShop.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.shops;
      const modifiedShopList = {
        ...jsonState,
        shops: jsonState.shops?.filter((shop) => shop._id !== action.payload),
      };
      state.loading = false;
      state.shops = modifiedShopList;
      state.error = {};
    },
    [deleteShop.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
  },
});
export const { clearStaffError } = shopSlice.actions;

export default shopSlice.reducer;
