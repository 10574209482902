import { createSlice } from '@reduxjs/toolkit';

import { covertToJSON } from '../../utils/stateToJson';
import {
  createModel,
  deleteModel,
  getAllModels,
  getModelDetails,
  updateModelDetails,
} from '../api/model';

export const modelSlice = createSlice({
  name: 'model',
  initialState: {
    loading: false,
    error: {},
    // staff
    models: {},
  },
  reducers: {
    clearStaffError: (state) => {
      state.error = true;
    },
  },
  extraReducers: {
    [createModel.pending]: (state) => {
      state.loading = true;
    },
    [createModel.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.models;
      const newModelList = jsonState?.models;

      const modifiedModelList = {
        ...jsonState,
        models: Array.isArray(newModelList) ? [action.payload, ...newModelList] : [action.payload],
      };
      state.loading = false;
      state.models = modifiedModelList;
      state.error = {};
    },
    [createModel.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [getAllModels.pending]: (state) => {
      state.loading = true;
    },
    [getAllModels.fulfilled]: (state, action) => {
      state.loading = false;
      state.models = action.payload;
      state.error = {};
    },
    [getAllModels.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [getModelDetails.pending]: (state) => {
      state.loading = true;
    },
    [getModelDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.bookingDetails = action.payload;
      state.error = {};
    },
    [getModelDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [updateModelDetails.pending]: (state) => {
      state.loading = true;
    },
    [updateModelDetails.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.models;
      const modifiedModelList = {
        ...jsonState,
        models: jsonState.models?.map((i) => (i._id === action.payload._id ? action?.payload : i)),
      };

      state.loading = false;
      state.models = modifiedModelList;
      state.error = {};
    },
    [updateModelDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [deleteModel.pending]: (state) => {
      state.loading = true;
    },
    [deleteModel.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.models;
      const modifiedModelList = {
        ...jsonState,
        models: jsonState.models?.filter((model) => model._id !== action.payload),
      };
      state.loading = false;
      state.models = modifiedModelList;
      state.error = {};
    },
    [deleteModel.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
  },
});
export const { clearStaffError } = modelSlice.actions;

export default modelSlice.reducer;
