import { createSlice } from '@reduxjs/toolkit';

import { covertToJSON } from '../../utils/stateToJson';
import {
  createModelCategory,
  deleteModelCategory,
  getAllModelCategories,
  getModelCategoryDetails,
  updateModelCategoryDetails,
} from '../api/modelCategory';

export const modelCategorySlice = createSlice({
  name: 'modelCategory',
  initialState: {
    loading: false,
    error: {},
    // staff
    modelCategories: {},
  },
  reducers: {
    clearStaffError: (state) => {
      state.error = true;
    },
  },
  extraReducers: {
    [createModelCategory.pending]: (state) => {
      state.loading = true;
    },
    [createModelCategory.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.modelCategories;
      const newModelCategoryList = jsonState?.modelcategories;

      const modifiedModelCategoryList = {
        ...jsonState,
        modelcategories: Array.isArray(newModelCategoryList)
          ? [action.payload, ...newModelCategoryList]
          : [action.payload],
      };
      state.loading = false;
      state.modelCategories = modifiedModelCategoryList;
      state.error = {};
    },
    [createModelCategory.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [getAllModelCategories.pending]: (state) => {
      state.loading = true;
    },
    [getAllModelCategories.fulfilled]: (state, action) => {
      state.loading = false;
      state.modelCategories = action.payload;
      state.error = {};
    },
    [getAllModelCategories.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [getModelCategoryDetails.pending]: (state) => {
      state.loading = true;
    },
    [getModelCategoryDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.bookingDetails = action.payload;
      state.error = {};
    },
    [getModelCategoryDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [updateModelCategoryDetails.pending]: (state) => {
      state.loading = true;
    },
    [updateModelCategoryDetails.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.modelCategories;
      const modifiedModelCategoryList = {
        ...jsonState,
        modelcategories: jsonState.modelcategories?.map((i) =>
          i._id === action.payload._id ? action?.payload : i
        ),
      };

      state.loading = false;
      state.modelCategories = modifiedModelCategoryList;
      state.error = {};
    },
    [updateModelCategoryDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [deleteModelCategory.pending]: (state) => {
      state.loading = true;
    },
    [deleteModelCategory.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.modelCategories;
      const modifiedModelCategoryList = {
        ...jsonState,
        modelcategories: jsonState.modelcategories?.filter(
          (modelCategory) => modelCategory._id !== action.payload
        ),
      };
      state.loading = false;
      state.modelCategories = modifiedModelCategoryList;
      state.error = {};
    },
    [deleteModelCategory.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
  },
});
export const { clearStaffError } = modelCategorySlice.actions;

export default modelCategorySlice.reducer;
