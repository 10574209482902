import PropTypes from 'prop-types';

import Pagination from '@mui/material/Pagination';
// @mui
import { Box, Card, Stack, Avatar, MenuItem, Typography, IconButton } from '@mui/material';

import Iconify from 'src/components/iconify';
import { usePopover } from 'src/components/custom-popover';
import CustomPopover from 'src/components/custom-popover/custom-popover';

ShopData.propTypes = {
  shopData: PropTypes.object,
  page: PropTypes.any,
  setPage: PropTypes.any,
  total: PropTypes.any,
  handleEditClick: PropTypes.any,
  handleDeleteClick: PropTypes.any,
};

export default function ShopData({
  shopData,
  page,
  setPage,
  total,
  handleEditClick,
  handleDeleteClick,
}) {
  const handleChange = (event, value) => {
    setPage(value);
  };
  return (
    <>
      <Box
        gap={3}
        display="grid"
        gridTemplateColumns={{
          xs: 'repeat(1, 1fr)',
          sm: 'repeat(2, 1fr)',
          md: 'repeat(4, 1fr)',
        }}
      >
        {shopData?.shops?.map((shop, key) => (
          <ShopCard
            key={key}
            shop={shop}
            handleEditClick={handleEditClick}
            handleDeleteClick={handleDeleteClick}
          />
        ))}
      </Box>
      {shopData?.total > 10 && (
        <Stack direction="row" justifyContent="center" mt={8}>
          <Pagination
            count={total}
            page={page}
            onChange={handleChange}
            className="custom-pagination"
          />
        </Stack>
      )}
    </>
  );
}

function ShopCard({ shop, handleEditClick, handleDeleteClick }) {
  const { name, phone, image, avatarUrl } = shop;

  const popover = usePopover();

  const handleDelete = (shopData) => {
    popover.onClose();
    handleDeleteClick(shopData);
  };

  const handleEdit = (shopData) => {
    popover.onClose();
    handleEditClick(shopData);
  };

  const firstLetter = name?.charAt(0).toUpperCase();

  return (
    <>
      <Card
        sx={{
          py: 5,
          display: 'flex',
          position: 'relative',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <Avatar
          alt={name}
          src={(image && image?.length > 0 && image[0]?.url) || avatarUrl}
          sx={{ width: 75, height: 75, mb: 3 }}
          className="shop-logo"
        >
          {firstLetter}
        </Avatar>

        <Typography variant="subtitle1" color="text.primary">
          {name}
        </Typography>

        <Typography variant="body2" sx={{ color: 'text.secondary', mb: 1, mt: 0.5 }}>
          {phone}
        </Typography>

        <IconButton
          color={popover.open ? 'inherit' : 'default'}
          onClick={popover.onOpen}
          sx={{ top: 8, right: 8, position: 'absolute' }}
        >
          <Iconify icon="eva:more-vertical-fill" />
        </IconButton>
      </Card>

      <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        arrow="right-top"
        sx={{ width: 140 }}
      >
        <MenuItem onClick={() => handleEdit(shop)}>
          <Iconify icon="solar:pen-bold" />
          Edit
        </MenuItem>

        <MenuItem onClick={() => handleDelete(shop)} sx={{ color: 'error.main' }}>
          <Iconify icon="solar:trash-bin-trash-bold" />
          Delete
        </MenuItem>
      </CustomPopover>
    </>
  );
}

ShopCard.propTypes = {
  shop: PropTypes.object,
  handleEditClick: PropTypes.any,
  handleDeleteClick: PropTypes.any,
};
