import { createAsyncThunk } from '@reduxjs/toolkit';

import { del, get, put, post } from './http';
import { activeSnack } from '../store/common';

export const createModelCategory = createAsyncThunk(
  'model-category/create',
  async ({ state, dispatch, handleClose, handleClear }) => {
    try {
      const URL = `/model-category/admin/new`;
      const response = await post(URL, state);
      if (response) {
        handleClose();
        handleClear();
        const cred = {
          page: 1,
          limit: 10,
          dispatch,
        };
        dispatch(getAllModelCategories(cred));
        dispatch(activeSnack({ type: 'success', message: 'Model Category created successfully' }));
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getAllModelCategories = createAsyncThunk(
  'model-category/list',
  async ({ page, search, dispatch, limit, model, kanthoora }) => {
    try {
      const response = await get(
        `/model-category/admin/all?page=${page || 1}&search=${search || ''}&limit=${limit || 5}&model=${model || ''}&kanthoora=${kanthoora || ''}`
      );
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getModelCategoryDetails = createAsyncThunk(
  'model-category/details',
  async ({ modelCategoryId, dispatch }) => {
    try {
      const response = await get(`/model-category/admin/${modelCategoryId || ''}`);
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const updateModelCategoryDetails = createAsyncThunk(
  'model-category/update',
  async ({ state, modelCategoryId, dispatch, handleClose }) => {
    try {
      const URL = `/model-category/admin/${modelCategoryId}`;

      const response = await put(URL, state);
      if (response) {
        if (handleClose) {
          handleClose();
        }
        const cred = {
          page: 1,
          limit: 10,
          dispatch,
        };
        dispatch(getAllModelCategories(cred));
        dispatch(activeSnack({ type: 'success', message: 'Model Category updated Successfully' }));
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const deleteModelCategory = createAsyncThunk(
  'model-category/delete',
  async ({ modelCategoryId, dispatch, handleCloseDeleteDialog }) => {
    try {
      const response = await del(`/model-category/admin/${modelCategoryId}/`);
      if (response) {
        handleCloseDeleteDialog();
        dispatch(activeSnack({ type: 'success', message: 'Model Category deleted Successfully' }));
        return response?._id;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
