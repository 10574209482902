import { MetaHelmet } from 'src/layouts/Helmet/Helmet';

import ClothBrand from './ClothBrandPage';

export default function AllClothBrands() {
  return (
    <>
      <MetaHelmet title="Shop" />
      <ClothBrand />
    </>
  );
}
