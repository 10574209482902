import * as React from 'react';
import PropTypes from 'prop-types';

import { LoadingButton } from '@mui/lab';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { Alert, Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import useMediaQuery from '@mui/material/useMediaQuery';
import DialogContentText from '@mui/material/DialogContentText';

export default function DeleteConfirmDialog({
  open,
  setOpen,
  onConfirmClick,
  item,
  title,
  loading,
}) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">{`Delete ${title || 'item'}?`}</DialogTitle>
        <DialogContent sx={{ maxWidth: '480px' }}>
          <DialogContentText>{item?.heading}</DialogContentText>
          {item?.alert && (
            <Alert severity="warning" sx={{ width: 1, alignItems: 'center', mt: '12px' }}>
              {item?.alert}
            </Alert>
          )}
        </DialogContent>
        <Stack direction="row" m={4} spacing={2}>
          <LoadingButton
            onClick={onConfirmClick}
            loading={loading}
            size="medium"
            sx={{
              backgroundColor: '#7A0916',
              color: 'white',
              width: '50%',
              '&:hover': {
                backgroundColor: '#7A0916',
                color: 'white',
              },
            }}
          >
            Delete
          </LoadingButton>
          <Button
            size="medium"
            sx={{ width: '50%', border: '1px solid grey' }}
            autoFocus
            onClick={handleClose}
          >
            Cancel
          </Button>
        </Stack>
      </Dialog>
    </div>
  );
}

DeleteConfirmDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  onConfirmClick: PropTypes.func,
  item: PropTypes.any,
  title: PropTypes.string,
  loading: PropTypes.any,
};
