import { createSlice } from '@reduxjs/toolkit';

import { covertToJSON } from '../../utils/stateToJson';
import {
  createVehicle,
  deleteVehicle,
  getAllVehicles,
  getVehicleDetails,
  updateVehicleDetails,
} from '../api/vehicle';

export const vehicleSlice = createSlice({
  name: 'vehicle',
  initialState: {
    loading: false,
    error: {},
    // staff
    vehicles: {},
    vehicleDetails: {},
  },
  reducers: {
    clearStaffError: (state) => {
      state.error = true;
    },
    clearVehicleDetails: (state) => {
      state.vehicleDetails = {};
    },
  },
  extraReducers: {
    [createVehicle.pending]: (state) => {
      state.loading = true;
    },
    [createVehicle.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.vehicles;
      const newVehicleList = jsonState?.vehicles;

      const modifiedVehicleList = {
        ...jsonState,
        vehicles: Array.isArray(newVehicleList)
          ? [action.payload, ...newVehicleList]
          : [action.payload],
      };
      state.loading = false;
      state.vehicles = modifiedVehicleList;
      state.error = {};
    },
    [createVehicle.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [getAllVehicles.pending]: (state) => {
      state.loading = true;
    },
    [getAllVehicles.fulfilled]: (state, action) => {
      state.loading = false;
      state.vehicles = action.payload;
      state.error = {};
    },
    [getAllVehicles.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [getVehicleDetails.pending]: (state) => {
      state.loading = true;
    },
    [getVehicleDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.vehicleDetails = action.payload;
      state.error = {};
    },
    [getVehicleDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [updateVehicleDetails.pending]: (state) => {
      state.loading = true;
    },
    [updateVehicleDetails.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.vehicles;
      const modifiedVehicleList = {
        ...jsonState,
        vehicles: jsonState.vehicles?.map((i) =>
          i._id === action.payload._id ? action?.payload : i
        ),
      };

      state.loading = false;
      state.vehicles = modifiedVehicleList;
      state.vehicleDetails = action.payload;
      state.error = {};
    },
    [updateVehicleDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [deleteVehicle.pending]: (state) => {
      state.loading = true;
    },
    [deleteVehicle.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.vehicles;
      const modifiedVehicleList = {
        ...jsonState,
        vehicles: jsonState.vehicles?.filter((vehicle) => vehicle._id !== action.payload),
      };
      state.loading = false;
      state.vehicles = modifiedVehicleList;
      state.error = {};
    },
    [deleteVehicle.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
  },
});
export const { clearStaffError, clearVehicleDetails } = vehicleSlice.actions;

export default vehicleSlice.reducer;
