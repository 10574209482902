import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useMemo, useState, useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';

import { LoadingButton } from '@mui/lab';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
// @mui
import { Stack, Alert, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';

import { createBrand, updateBrandDetails } from 'src/server/api/brand';

// components
import { RHFTextField } from 'src/components/hook-form';
import FormProvider from 'src/components/hook-form/form-provider';
import { FileUploadCustom } from 'src/components/CustomFields/ImageUpload';

ClothBrandCrud.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  setBrandData: PropTypes.func,
  isUpdate: PropTypes.object,
};

export default function ClothBrandCrud({ open, setOpen, isUpdate, setBrandData }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleClose = () => {
    setOpen(false);
    setBrandData({});
  };
  const dispatch = useDispatch();

  const { loading, error } = useSelector((state) => ({
    loading: state.brand.loading,
    error: state.brand.error,
  }));

  const [brandLogo, setBrandLogo] = useState([]);

  const BrandSchema = Yup.object().shape({
    name: Yup.string().required(),
    description: Yup.string(),
  });

  const defaultValues = useMemo(
    () => ({
      name: isUpdate?.name || '',
      description: isUpdate?.description || '',
      image: brandLogo || [],
    }),
    [brandLogo, isUpdate?.description, isUpdate?.name]
  );

  useEffect(() => {
    if (isUpdate?._id) {
      setBrandLogo(isUpdate?.image || []);
    }
  }, [isUpdate]);

  const methods = useForm({
    resolver: yupResolver(BrandSchema),
    defaultValues,
  });
  const {
    handleSubmit,
    formState: { isSubmitting },
    reset,
  } = methods;

  const handleClear = () => {
    reset(defaultValues);
  };

  const renderForm = (
    <>
      <Stack sx={{ width: '100%', marginBottom: '15px' }} spacing={2}>
        {error.message && <Alert severity="error">{error?.message}</Alert>}
      </Stack>
      <Stack spacing={2} direction="row" mb="24px">
        <RHFTextField name="name" label="Name" type="text" required />
      </Stack>
      <Stack spacing={2} direction="row" mb="24px">
        <RHFTextField name="description" type="text" label="Description" multiline rows={4} />
      </Stack>
      <Stack spacing={2} direction="row" mb="24px">
        <FileUploadCustom
          setImagesPreview={setBrandLogo}
          imagesPreview={brandLogo}
          title="Upload Logo"
          imageSize={4}
          // isMultiple
          // uploadUrl="stemxpert"
          // onChangeUpdate={updateProductWhenImageChange}
          // isUpdate={isUpdateData}
        />
      </Stack>
    </>
  );

  const onSubmit = handleSubmit(async (data) => {
    const credentialsCreate = {
      state: {
        ...data,
        image: brandLogo,
      },
      dispatch,
      handleClose,
      handleClear,
    };

    if (isUpdate?._id) {
      const credentialsUpdate = {
        state: {
          ...data,
          image: brandLogo,
        },
        brandId: isUpdate?._id,
        dispatch,
        handleClose,
        handleClear,
      };
      dispatch(updateBrandDetails(credentialsUpdate));
    } else {
      dispatch(createBrand(credentialsCreate));
    }
  });

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {isUpdate?._id ? 'Update Brand' : 'New Brand'}
        </DialogTitle>
        <DialogContent sx={{ minWidth: '450px' }}>
          <FormProvider methods={methods} onSubmit={onSubmit}>
            {renderForm}
            <Stack direction="column">
              <DialogActions
                sx={{
                  mt: 2,
                  p: 0,
                  py: 5,
                }}
              >
                <Stack direction="row" spacing={1}>
                  <LoadingButton
                    sx={{
                      padding: '5px 20px',
                    }}
                    size="medium"
                    type="submit"
                    variant="contained"
                    color="inherit"
                    loading={isSubmitting || loading}
                    autoFocus
                  >
                    {isUpdate?._id ? 'Update' : 'Create'}
                  </LoadingButton>
                  <Button size="medium" onClick={handleClose}>
                    Cancel
                  </Button>
                </Stack>
              </DialogActions>
            </Stack>
          </FormProvider>
        </DialogContent>
      </Dialog>
    </div>
  );
}
