import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useMemo, useState, useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';

import { LoadingButton } from '@mui/lab';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
// @mui
import {
  Box,
  Stack,
  Alert,
  Dialog,
  TextField,
  DialogTitle,
  Autocomplete,
  DialogContent,
  DialogActions,
} from '@mui/material';

import { getAllModels } from 'src/server/api/model';
import { getAllKanthooras } from 'src/server/api/kanthoora';
import { createModelCategory, updateModelCategoryDetails } from 'src/server/api/modelCategory';

// components
import { RHFTextField } from 'src/components/hook-form';
import FormProvider from 'src/components/hook-form/form-provider';
import { FileUploadCustom } from 'src/components/CustomFields/ImageUpload';

ModelCategoryCrud.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  setModelCategoryData: PropTypes.func,
  isUpdate: PropTypes.object,
};

export default function ModelCategoryCrud({ open, setOpen, isUpdate, setModelCategoryData }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleClose = () => {
    setOpen(false);
    setModelCategoryData({});
  };
  const dispatch = useDispatch();

  const { loading, error, kanthooras, models } = useSelector((state) => ({
    kanthooras: state.kanthoora.kanthooras,
    models: state.model.models,
    loading: state.modelCategory.loading,
    error: state.modelCategory.error,
  }));

  const [modelCategoryImage, setModelCategoryImage] = useState([]);
  const [selectedModel, setSelectedModel] = useState({
    label: '',
    value: '',
  });
  const [searchTextModel, setSearchTextModel] = useState('');
  const [selectedKanthoora, setSelectedKanthoora] = useState({
    label: '',
    value: '',
  });
  const [searchTextKanthoora, setSearchTextKanthoora] = useState('');

  const ModelCategorySchema = Yup.object().shape({
    name: Yup.string().required(),
    description: Yup.string(),
  });

  const defaultKanthoora = useMemo(
    () => ({
      label: isUpdate?.kanthoora?.name || '',
      value: isUpdate?.kanthoora?._id || '',
    }),
    [isUpdate]
  );

  const defaultModel = useMemo(
    () => ({
      label: isUpdate?.model?.name || '',
      value: isUpdate?.model?._id || '',
    }),
    [isUpdate]
  );

  const defaultValues = useMemo(
    () => ({
      name: isUpdate?.name || '',
      kanthoora: defaultKanthoora || {},
      model: defaultModel || {},
      description: isUpdate?.description || '',
      images: modelCategoryImage || [],
    }),
    [modelCategoryImage, isUpdate?.description, isUpdate?.name, defaultKanthoora, defaultModel]
  );

  useEffect(() => {
    if (isUpdate?._id) {
      setModelCategoryImage(isUpdate?.images || []);
      setSelectedKanthoora({
        label: isUpdate?.kanthoora?.name,
        value: isUpdate?.kanthoora?._id,
      });
      setSelectedModel({
        label: isUpdate?.model?.name || '',
        value: isUpdate?.model?._id || '',
      });
    }
  }, [isUpdate]);

  const methods = useForm({
    resolver: yupResolver(ModelCategorySchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
    reset,
  } = methods;

  const handleClear = () => {
    reset(defaultValues);
  };

  useEffect(() => {
    const credentials = {
      page: 1,
      search: searchTextModel,
      dispatch,
      limit: 10,
    };
    dispatch(getAllModels(credentials));
  }, [dispatch, searchTextModel]);

  const modelOptions = [
    ...(models?.models || []).map((result) => ({
      label: result?.name,
      value: result?._id,
    })),
  ];

  const handleAutocompleteChangeModel = (_, newValue) => {
    setSelectedModel({
      value: newValue?.value || '',
      label: newValue?.label || '',
    });
  };

  const handleSearchModel = (event) => {
    setSearchTextModel(event.target.value);
  };

  useEffect(() => {
    const credentials = {
      page: 1,
      search: searchTextKanthoora,
      dispatch,
      limit: 10,
    };
    dispatch(getAllKanthooras(credentials));
  }, [dispatch, searchTextKanthoora]);

  const kanthooraOptions = [
    ...(kanthooras?.kanthooras || []).map((result) => ({
      label: result?.name,
      value: result?._id,
    })),
  ];

  const handleAutocompleteChangeKanthoora = (_, newValue) => {
    setSelectedKanthoora({
      value: newValue?.value || '',
      label: newValue?.label || '',
    });
  };

  const handleSearchKanthoora = (event) => {
    setSearchTextKanthoora(event.target.value);
  };

  const renderForm = (
    <>
      <Stack sx={{ width: '100%', marginBottom: '15px' }} spacing={2}>
        {error.message && <Alert severity="error">{error?.message}</Alert>}
      </Stack>
      <Stack spacing={2} direction="row" mb="24px">
        <RHFTextField name="name" label="Name" type="text" required />
      </Stack>
      <Stack spacing={2} direction="row" mb="24px">
        <Autocomplete
          id="partner-select-demo"
          sx={{ width: '100%' }}
          options={modelOptions}
          autoHighlight
          getOptionLabel={(option) => option.label}
          isOptionEqualToValue={(option, value) => option.value === value.value}
          renderOption={(props, option) => (
            <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
              {option?.label}
            </Box>
          )}
          value={selectedModel}
          onChange={handleAutocompleteChangeModel}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Model"
              required
              // name="head_partner_type"
              onChange={handleSearchModel}
              inputProps={{
                ...params.inputProps,
              }}
              size="medium"
            />
          )}
        />
      </Stack>
      <Stack spacing={2} direction="row" mb="24px">
        <Autocomplete
          id="partner-select-demo"
          sx={{ width: '100%' }}
          options={kanthooraOptions}
          autoHighlight
          getOptionLabel={(option) => option.label}
          isOptionEqualToValue={(option, value) => option.value === value.value}
          renderOption={(props, option) => (
            <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
              {option?.label}
            </Box>
          )}
          value={selectedKanthoora}
          onChange={handleAutocompleteChangeKanthoora}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Kanthoora"
              required
              // name="head_partner_type"
              onChange={handleSearchKanthoora}
              inputProps={{
                ...params.inputProps,
              }}
              size="medium"
            />
          )}
        />
      </Stack>
      <Stack spacing={2} direction="row" mb="24px">
        <RHFTextField name="description" type="text" label="Description" multiline rows={4} />
      </Stack>
      <Stack spacing={2} direction="row" mb="24px">
        <FileUploadCustom
          setImagesPreview={setModelCategoryImage}
          imagesPreview={modelCategoryImage}
          title="Upload Image"
          imageSize={4}
          isMultiple
          // uploadUrl="stemxpert"
          // onChangeUpdate={updateProductWhenImageChange}
          // isUpdate={isUpdateData}
        />
      </Stack>
    </>
  );

  const onSubmit = handleSubmit(async (data) => {
    const credentialsCreate = {
      state: {
        ...data,
        kanthoora: selectedKanthoora?.value,
        model: selectedModel?.value,
        images: modelCategoryImage,
      },
      dispatch,
      handleClose,
      handleClear,
    };

    if (isUpdate?._id) {
      const credentialsUpdate = {
        state: {
          ...data,
          kanthoora: selectedKanthoora?.value,
          model: selectedModel?.value,
          images: modelCategoryImage,
        },
        modelCategoryId: isUpdate?._id,
        dispatch,
        handleClose,
        handleClear,
      };
      dispatch(updateModelCategoryDetails(credentialsUpdate));
    } else {
      dispatch(createModelCategory(credentialsCreate));
    }
  });

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {isUpdate?._id ? 'Update Model Category' : 'New Model Category'}
        </DialogTitle>
        <DialogContent sx={{ minWidth: '450px' }}>
          <FormProvider methods={methods} onSubmit={onSubmit}>
            {renderForm}
            <Stack direction="column">
              <DialogActions
                sx={{
                  mt: 2,
                  p: 0,
                  py: 5,
                }}
              >
                <Stack direction="row" spacing={1}>
                  <LoadingButton
                    sx={{
                      padding: '5px 20px',
                    }}
                    size="medium"
                    type="submit"
                    variant="contained"
                    color="inherit"
                    loading={isSubmitting || loading}
                    autoFocus
                  >
                    {isUpdate?._id ? 'Update' : 'Create'}
                  </LoadingButton>
                  <Button size="medium" onClick={handleClose}>
                    Cancel
                  </Button>
                </Stack>
              </DialogActions>
            </Stack>
          </FormProvider>
        </DialogContent>
      </Dialog>
    </div>
  );
}
