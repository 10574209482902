// import { lazy, Suspense } from 'react';
// import { Outlet } from 'react-router-dom';

// import { AuthRoles } from 'src/routes/routes/paths';

// import SettingsPage from 'src/pages/Admin/settings';
// import LoginAdmin from 'src/assets/other/LoginAdmin.png';

// import { SplashScreen } from 'src/components/loading-screen';

// const AuthLayout = lazy(() => import('src/layouts/auth/authLayout'));
// const LoginAdminPage = lazy(() => import('src/pages/Common/Auth/Login'));

// // const LandingPage = lazy(() => import('src/pages/Common/LandingWeb/LandingPage'));
// const CompactLayout = lazy(() => import('src/layouts/compact'));
// const CountryPage = lazy(() => import('src/pages/Common/Country/CountryPage'));
// const AllVehicles = lazy(() => import('src/pages/Common/Vehicle'));
// const AllBrands = lazy(() => import('src/pages/Common/Brand'));
// const VehicleView = lazy(() => import('src/pages/Common/Vehicle/SingleView'));
// const VehicleCreatePage = lazy(() => import('src/pages/Common/Vehicle/Crud/create'));
// const VehicleUpdatePage = lazy(() => import('src/pages/Common/Vehicle/Crud/update'));
// const AllCustomers = lazy(() => import('src/pages/Common/Customer'));
// const CustomerView = lazy(() => import('src/pages/Common/Customer/SingleView'));
// const CustomerCreatePage = lazy(() => import('src/pages/Common/Customer/Crud/create'));
// const CustomerUpdatePage = lazy(() => import('src/pages/Common/Customer/Crud/update'));
// const AllBookings = lazy(() => import('src/pages/Common/Booking'));
// const BookingView = lazy(() => import('src/pages/Common/Booking/SingleView'));
// const BookingCreatePage = lazy(() => import('src/pages/Common/Booking/Crud/create'));
// const BookingUpdatePage = lazy(() => import('src/pages/Common/Booking/Crud/update'));
// const AllRentals = lazy(() => import('src/pages/Common/Rental'));
// const RentalView = lazy(() => import('src/pages/Common/Rental/SingleView'));
// const RentalCreatePage = lazy(() => import('src/pages/Common/Rental/Crud/create'));
// const RentalUpdatePage = lazy(() => import('src/pages/Common/Rental/Crud/update'));
// const AllTransactions = lazy(() => import('src/pages/Common/Transaction'));
// const AllExpenses = lazy(() => import('src/pages/Common/Expense'));
// const ExpenseCreatePage = lazy(() => import('src/pages/Common/Expense/Crud/create'));
// const AllBanks = lazy(() => import('src/pages/Common/Bank'));
// const AllIncomes = lazy(() => import('src/pages/Common/Income'));
// const IncomeCreatePage = lazy(() => import('src/pages/Common/Income/Crud/create'));
// const BalanceSheet = lazy(() => import('src/pages/Common/BalanceSheet'));
// const RentalInvoiceDetailsView = lazy(() => import('src/pages/Common/PDFDownload/RentalInvoice'));
// const TransactionInvoiceDetailsView = lazy(() => import('src/pages/Common/PDFDownload/TransactionInvoice'));

// const Page500 = lazy(() => import('src/pages/Common/Static/500'));
// const Page403 = lazy(() => import('src/pages/Common/Static/403'));
// const Page404 = lazy(() => import('src/pages/Common/Static/404'));

// const publicRoutes = [
//   // {
//   //   element: (
//   //     <Suspense fallback={<SplashScreen />}>
//   //       <Outlet />
//   //     </Suspense>
//   //   ),
//   //   children: [{ path: 'landing', element: <LandingPage /> }],
//   // },
//   {
//     element: (
//       <CompactLayout>
//         <Suspense fallback={<SplashScreen />}>
//           <Outlet />
//         </Suspense>
//       </CompactLayout>
//     ),
//     children: [
//       { path: '500', element: <Page500 /> },
//       { path: '404', element: <Page404 /> },
//       { path: '403', element: <Page403 /> },
//     ],
//   },
//   {
//     path: 'login',
//     element: (
//       <AuthLayout image={LoginAdmin} title="Authenticate as an administrator" userRole="admin">
//         <LoginAdminPage role={AuthRoles.admin} />
//       </AuthLayout>
//     ),
//   },
// ];

// // super admin
// const adminRoutes = [
//   {
//     path: 'brand',
//     element: <AllBrands />,
//   },
//   {
//     path: 'vehicle',
//     element: <AllVehicles />,
//   },
//   {
//     path: 'vehicle/:id',
//     element: <VehicleView />,
//   },
//   {
//     path: 'vehicle/create/new',
//     element: <VehicleCreatePage />,
//   },
//   {
//     path: 'vehicle/update/:id',
//     element: <VehicleUpdatePage />,
//   },
//   {
//     path: 'customer',
//     element: <AllCustomers />,
//   },
//   {
//     path: 'customer/:id',
//     element: <CustomerView />,
//   },
//   {
//     path: 'customer/create/new',
//     element: <CustomerCreatePage />,
//   },
//   {
//     path: 'customer/update/:id',
//     element: <CustomerUpdatePage />,
//   },
//   {
//     path: 'booking',
//     element: <AllBookings />,
//   },
//   {
//     path: 'booking/:id',
//     element: <BookingView />,
//   },
//   {
//     path: 'booking/create/new',
//     element: <BookingCreatePage />,
//   },
//   {
//     path: 'booking/update/:id',
//     element: <BookingUpdatePage />,
//   },
//   {
//     path: 'rental',
//     element: <AllRentals />,
//   },
//   {
//     path: 'rental/:id',
//     element: <RentalView />,
//   },
//   {
//     path: 'rental/create/new',
//     element: <RentalCreatePage />,
//   },
//   {
//     path: 'rental/update/:id',
//     element: <RentalUpdatePage />,
//   },
//   {
//     path: 'transaction',
//     element: <AllTransactions />,
//   },
//   {
//     path: 'expense',
//     element: <AllExpenses />,
//   },
//   {
//     path: 'expense/create/new',
//     element: <ExpenseCreatePage />,
//   },
//   {
//     path: 'bank',
//     element: <AllBanks />,
//   },
//   {
//     path: 'income',
//     element: <AllIncomes />,
//   },
//   {
//     path: 'income/create/new',
//     element: <IncomeCreatePage />,
//   },
//   {
//     path: 'balance-sheet',
//     element: <BalanceSheet />,
//   },
//   {
//     path: 'rental-invoice/:id',
//     element: <RentalInvoiceDetailsView />,
//   },
//   {
//     path: 'transaction-invoice/:id',
//     element: <TransactionInvoiceDetailsView />,
//   },
//   {
//     path: 'country',
//     element: <CountryPage />,
//   },
//   {
//     path: 'settings',
//     element: <SettingsPage />,
//   },
// ];

// export { adminRoutes, publicRoutes };

import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import { AuthRoles } from 'src/routes/routes/paths';

import AllShops from 'src/pages/Common/Shop';
import AllCloths from 'src/pages/Common/Cloth';
import AllModels from 'src/pages/Common/Model';
import SettingsPage from 'src/pages/Admin/settings';
import LoginAdmin from 'src/assets/other/Login.jpg';
import AllKanthooras from 'src/pages/Common/Kanthoora';
import AllClothBrands from 'src/pages/Common/ClothBrand';
import AllModelCategories from 'src/pages/Common/ModelCategory';

import { SplashScreen } from 'src/components/loading-screen';

const AuthLayout = lazy(() => import('src/layouts/auth/authLayout'));
const LoginAdminPage = lazy(() => import('src/pages/Common/Auth/Login'));

// const LandingPage = lazy(() => import('src/pages/Common/LandingWeb/LandingPage'));
const CompactLayout = lazy(() => import('src/layouts/compact'));
const CountryPage = lazy(() => import('src/pages/Common/Country/CountryPage'));
const AllVehicles = lazy(() => import('src/pages/Common/Vehicle'));
const VehicleView = lazy(() => import('src/pages/Common/Vehicle/SingleView'));
const VehicleCreatePage = lazy(() => import('src/pages/Common/Vehicle/Crud/create'));
const VehicleUpdatePage = lazy(() => import('src/pages/Common/Vehicle/Crud/update'));
const AllCustomers = lazy(() => import('src/pages/Common/Customer'));
const CustomerView = lazy(() => import('src/pages/Common/Customer/SingleView'));
const CustomerCreatePage = lazy(() => import('src/pages/Common/Customer/Crud/create'));
const CustomerUpdatePage = lazy(() => import('src/pages/Common/Customer/Crud/update'));
const AllBookings = lazy(() => import('src/pages/Common/Booking'));
const BookingView = lazy(() => import('src/pages/Common/Booking/SingleView'));
const BookingCreatePage = lazy(() => import('src/pages/Common/Booking/Crud/create'));
const BookingUpdatePage = lazy(() => import('src/pages/Common/Booking/Crud/update'));
const AllRentals = lazy(() => import('src/pages/Common/Rental'));
const RentalView = lazy(() => import('src/pages/Common/Rental/SingleView'));
const RentalCreatePage = lazy(() => import('src/pages/Common/Rental/Crud/create'));
const RentalUpdatePage = lazy(() => import('src/pages/Common/Rental/Crud/update'));
const AllTransactions = lazy(() => import('src/pages/Common/Transaction'));
const AllExpenses = lazy(() => import('src/pages/Common/Expense'));
const ExpenseCreatePage = lazy(() => import('src/pages/Common/Expense/Crud/create'));
const AllBanks = lazy(() => import('src/pages/Common/Bank'));
const AllIncomes = lazy(() => import('src/pages/Common/Income'));
const IncomeCreatePage = lazy(() => import('src/pages/Common/Income/Crud/create'));
const BalanceSheet = lazy(() => import('src/pages/Common/BalanceSheet'));
const RentalInvoiceDetailsView = lazy(() => import('src/pages/Common/PDFDownload/RentalInvoice'));
const TransactionInvoiceDetailsView = lazy(
  () => import('src/pages/Common/PDFDownload/TransactionInvoice')
);
const InvoiceView = lazy(() => import('src/pages/Common/PDFDownload/InvoiceView'));
const AgreementDetailsView = lazy(() => import('src/pages/Common/PDFDownload/Agreement'));

const Page500 = lazy(() => import('src/pages/Common/Static/500'));
const Page403 = lazy(() => import('src/pages/Common/Static/403'));
const Page404 = lazy(() => import('src/pages/Common/Static/404'));

const publicRoutes = [
  // {
  //   element: (
  //     <Suspense fallback={<SplashScreen />}>
  //       <Outlet />
  //     </Suspense>
  //   ),
  //   children: [{ path: 'landing', element: <LandingPage /> }],
  // },
  {
    element: (
      <CompactLayout>
        <Suspense fallback={<SplashScreen />}>
          <Outlet />
        </Suspense>
      </CompactLayout>
    ),
    children: [
      { path: '500', element: <Page500 /> },
      { path: '404', element: <Page404 /> },
      { path: '403', element: <Page403 /> },
    ],
  },
  {
    path: 'login',
    element: (
      <AuthLayout image={LoginAdmin} title="Authenticate as an administrator" userRole="admin">
        <LoginAdminPage role={AuthRoles.admin} />
      </AuthLayout>
    ),
  },
];

// super admin
const adminRoutes = [
  {
    path: 'shop',
    element: <AllShops />,
  },
  {
    path: 'brand',
    element: <AllClothBrands />,
  },
  {
    path: 'cloth',
    element: <AllCloths />,
  },
  {
    path: 'kanthoora',
    element: <AllKanthooras />,
  },
  {
    path: 'model',
    element: <AllModels />,
  },
  {
    path: 'model-category',
    element: <AllModelCategories />,
  },
  {
    path: 'vehicle',
    element: <AllVehicles />,
  },
  {
    path: 'vehicle/:id',
    element: <VehicleView />,
  },
  {
    path: 'vehicle/create/new',
    element: <VehicleCreatePage />,
  },
  {
    path: 'vehicle/update/:id',
    element: <VehicleUpdatePage />,
  },
  {
    path: 'customer',
    element: <AllCustomers />,
  },
  {
    path: 'customer/:id',
    element: <CustomerView />,
  },
  {
    path: 'customer/create/new',
    element: <CustomerCreatePage />,
  },
  {
    path: 'customer/update/:id',
    element: <CustomerUpdatePage />,
  },
  {
    path: 'booking',
    element: <AllBookings />,
  },
  {
    path: 'booking/:id',
    element: <BookingView />,
  },
  {
    path: 'booking/create/new',
    element: <BookingCreatePage />,
  },
  {
    path: 'booking/update/:id',
    element: <BookingUpdatePage />,
  },
  {
    path: 'rental',
    element: <AllRentals />,
  },
  {
    path: 'rental/:id',
    element: <RentalView />,
  },
  {
    path: 'rental/create/new',
    element: <RentalCreatePage />,
  },
  {
    path: 'rental/update/:id',
    element: <RentalUpdatePage />,
  },
  {
    path: 'transaction',
    element: <AllTransactions />,
  },
  {
    path: 'expense',
    element: <AllExpenses />,
  },
  {
    path: 'expense/create/new',
    element: <ExpenseCreatePage />,
  },
  {
    path: 'bank',
    element: <AllBanks />,
  },
  {
    path: 'income',
    element: <AllIncomes />,
  },
  {
    path: 'income/create/new',
    element: <IncomeCreatePage />,
  },
  {
    path: 'balance-sheet',
    element: <BalanceSheet />,
  },
  {
    path: 'rental-invoice/:id',
    element: <RentalInvoiceDetailsView />,
  },
  {
    path: 'transaction-invoice/:id',
    element: <TransactionInvoiceDetailsView />,
  },
  {
    path: 'invoice-view/:id',
    element: <InvoiceView />,
  },
  {
    path: 'agreement/:id',
    element: <AgreementDetailsView />,
  },
  {
    path: 'country',
    element: <CountryPage />,
  },
  {
    path: 'settings',
    element: <SettingsPage />,
  },
];

// staff
const staffRoutes = [
  {
    path: 'vehicle',
    element: <AllVehicles />,
  },
  {
    path: 'vehicle/:id',
    element: <VehicleView />,
  },
  {
    path: 'vehicle/create/new',
    element: <VehicleCreatePage />,
  },
  {
    path: 'vehicle/update/:id',
    element: <VehicleUpdatePage />,
  },
  {
    path: 'customer',
    element: <AllCustomers />,
  },
  {
    path: 'customer/:id',
    element: <CustomerView />,
  },
  {
    path: 'customer/create/new',
    element: <CustomerCreatePage />,
  },
  {
    path: 'customer/update/:id',
    element: <CustomerUpdatePage />,
  },
  {
    path: 'booking',
    element: <AllBookings />,
  },
  {
    path: 'booking/:id',
    element: <BookingView />,
  },
  {
    path: 'booking/create/new',
    element: <BookingCreatePage />,
  },
  {
    path: 'booking/update/:id',
    element: <BookingUpdatePage />,
  },
  {
    path: 'rental',
    element: <AllRentals />,
  },
  {
    path: 'rental/:id',
    element: <RentalView />,
  },
  {
    path: 'rental/create/new',
    element: <RentalCreatePage />,
  },
  {
    path: 'rental/update/:id',
    element: <RentalUpdatePage />,
  },
  {
    path: 'transaction',
    element: <AllTransactions />,
  },
  {
    path: 'expense',
    element: <AllExpenses />,
  },
  {
    path: 'rental-invoice/:id',
    element: <RentalInvoiceDetailsView />,
  },
  {
    path: 'agreement/:id',
    element: <AgreementDetailsView />,
  },
  {
    path: 'transaction-invoice/:id',
    element: <TransactionInvoiceDetailsView />,
  },
  {
    path: 'invoice-view/:id',
    element: <InvoiceView />,
  },
  {
    path: 'country',
    element: <CountryPage />,
  },
  {
    path: 'settings',
    element: <SettingsPage />,
  },
];

export { adminRoutes, staffRoutes, publicRoutes };
