import { createAsyncThunk } from '@reduxjs/toolkit';

import { del, get, put, post } from './http';
import { activeSnack } from '../store/common';

export const createTailor = createAsyncThunk(
  'tailor/create',
  async ({ state, dispatch, handleClose, handleClear }) => {
    try {
      const URL = `/tailor/admin/new`;
      const response = await post(URL, state);
      if (response) {
        handleClose();
        handleClear();
        dispatch(activeSnack({ type: 'success', message: 'Tailor created successfully' }));
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getAllTailors = createAsyncThunk(
  'tailor/list',
  async ({ page, search, dispatch, limit }) => {
    try {
      const response = await get(
        `/tailor/admin/all?page=${page && page}&search=${search && search}&limit=${limit || 5}`
      );
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getTailorDetails = createAsyncThunk(
  'tailor/details',
  async ({ tailorId, dispatch }) => {
    try {
      const response = await get(`/tailor/admin/${tailorId || ''}`);
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const updateTailorDetails = createAsyncThunk(
  'tailor/update',
  async ({ state, tailorId, dispatch, handleClose }) => {
    try {
      const URL = `/tailor/admin/${tailorId}`;

      const response = await put(URL, state);
      if (response) {
        if (handleClose) {
          handleClose();
        }
        dispatch(activeSnack({ type: 'success', message: 'Tailor updated Successfully' }));
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const deleteTailor = createAsyncThunk(
  'tailor/delete',
  async ({ tailorId, dispatch, handleCloseDeleteDialog }) => {
    try {
      const response = await del(`/tailor/admin/${tailorId}/`);
      if (response) {
        handleCloseDeleteDialog();
        dispatch(activeSnack({ type: 'success', message: 'Tailor deleted Successfully' }));
        return response?._id;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
