import { MetaHelmet } from 'src/layouts/Helmet/Helmet';

import Shop from './ShopPage';

export default function AllShops() {
  return (
    <>
      <MetaHelmet title="Shop" />
      <Shop />
    </>
  );
}
