import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// @mui
import { Stack, Paper, Button, Container, Typography, CircularProgress } from '@mui/material';

import { paths } from 'src/routes/routes/paths';

import { useResponsive } from 'src/hooks/use-responsive';

import { deleteBrand, getAllBrands } from 'src/server/api/brand';

// components
import Iconify from 'src/components/iconify';
import EmptyContent from 'src/components/empty-content/empty-content';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs/custom-breadcrumbs';

// sections
import DeleteConfirmDialog from 'src/sections/common/DeleteConfirm';
import ClothBrandCrud from 'src/sections/ClothBrand/ClothBrandCrud';
import ClothBrandData from 'src/sections/ClothBrand/ClothBrandData';
import ClothBrandSearch from 'src/sections/ClothBrand/ClothBrandSearch';

export default function ClothBrand() {
  const dispatch = useDispatch();
  const isMobile = useResponsive('down', 'sm');

  const { brands, loading } = useSelector((state) => ({
    brands: state.brand.brands,
    loading: state.brand.loading,
  }));

  const item = {
    heading: 'Are you sure you want to delete this brand? This action cannot be undone.',
  };

  const [openBrandCrudDialog, setOpenBrandCrudDialog] = useState(false);
  const [brandData, setBrandData] = useState({});
  const [openDeleteConfirmDialog, setOpenDeleteConfirmDialog] = useState(false);

  const [searchText, setSearchText] = useState('');
  const [brandPage, setBrandPage] = useState(1);
  const total = brands?.total;
  const totalBrands = Math.ceil(total / 12);
  // const identifier = 'course';
  // const storedRowsPerPage = localStorage.getItem(`rowsPerPage_${identifier}`);

  const handleCloseDeleteDialog = () => {
    setOpenDeleteConfirmDialog(false);
  };

  const handleDeleteClick = (brand) => {
    setBrandData(brand);
    setOpenDeleteConfirmDialog(true);
  };

  const handleDeleteConfirmClick = () => {
    const credentials = {
      dispatch,
      brandId: brandData?._id,
      handleCloseDeleteDialog,
    };
    dispatch(deleteBrand(credentials));
  };

  const handleEditClick = (brand) => {
    if (brand) {
      setBrandData(brand);
    }
    setOpenBrandCrudDialog(true);
  };

  const handleSearch = (value) => {
    setSearchText(value);
  };

  useEffect(() => {
    const credentials = {
      page: brandPage,
      search: searchText,
      dispatch,
      limit: 12,
    };
    dispatch(getAllBrands(credentials));
  }, [dispatch, searchText, brandPage]);

  return (
    <>
      {openBrandCrudDialog && (
        <ClothBrandCrud
          setOpen={setOpenBrandCrudDialog}
          open={openBrandCrudDialog}
          isUpdate={brandData}
          setBrandData={setBrandData}
        />
      )}

      <DeleteConfirmDialog
        onConfirmClick={handleDeleteConfirmClick}
        setOpen={setOpenDeleteConfirmDialog}
        open={openDeleteConfirmDialog}
        item={item}
        loading={loading}
        title={`Brand "${brandData?.name}"`}
      />

      <Container maxWidth="xl">
        <CustomBreadcrumbs
          heading="Brand"
          links={[
            {
              name: 'Dashboard',
              href: paths.dashboard.root,
            },

            {
              name: 'Brand',
            },
          ]}
          action={
            <Stack flexDirection="row" spacing={2}>
              <Button
                onClick={() => setOpenBrandCrudDialog(true)}
                variant="contained"
                color="inherit"
                startIcon={<Iconify icon="mingcute:add-line" />}
              >
                New Brand
              </Button>
            </Stack>
          }
          sx={{
            mb: { xs: 3, md: 5 },
          }}
        />
        <Stack
          spacing={3}
          justifyContent="space-between"
          alignItems={{ sm: 'center' }}
          direction={{ xs: 'column', sm: 'row' }}
          mb={3}
        >
          <ClothBrandSearch onSearch={handleSearch} isMobile={isMobile} />
          <Typography variant="subtitle1" pr={5}>
            Total: {brands?.total || 0}
          </Typography>
        </Stack>
        {loading ? (
          <Paper
            sx={{
              textAlign: 'center',
            }}
          >
            <CircularProgress color="inherit" />
          </Paper>
        ) : (
          <>
            {brands?.total > 0 ? (
              <ClothBrandData
                brandData={brands}
                handleEditClick={handleEditClick}
                handleDeleteClick={handleDeleteClick}
                page={brandPage}
                setPage={setBrandPage}
                total={totalBrands}
              />
            ) : (
              <EmptyContent
                filled
                title="No Data"
                sx={{
                  py: 10,
                }}
              />
            )}
          </>
        )}
      </Container>
    </>
  );
}
