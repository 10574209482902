import { createSlice } from '@reduxjs/toolkit';

import { covertToJSON } from '../../utils/stateToJson';
import {
  createTransaction,
  deleteTransaction,
  getAllTransactions,
  getTransactionDetails,
  updateTransactionDetails,
} from '../api/transaction';

export const transactionSlice = createSlice({
  name: 'transaction',
  initialState: {
    loading: false,
    error: {},
    // staff
    transactions: {},
    transactionDetails: {},
  },
  reducers: {
    clearStaffError: (state) => {
      state.error = true;
    },
    clearTransactionDetails: (state) => {
      state.transactionDetails = {};
    },
  },
  extraReducers: {
    [createTransaction.pending]: (state) => {
      state.loading = true;
    },
    [createTransaction.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.transactions;
      const newTransactionList = jsonState?.transactions;

      const modifiedTransactionList = {
        ...jsonState,
        transactions: Array.isArray(newTransactionList)
          ? [action.payload, ...newTransactionList]
          : [action.payload],
      };
      state.loading = false;
      state.transactions = modifiedTransactionList;
      state.error = {};
    },
    [createTransaction.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [getAllTransactions.pending]: (state) => {
      state.loading = true;
    },
    [getAllTransactions.fulfilled]: (state, action) => {
      state.loading = false;
      state.transactions = action.payload;
      state.error = {};
    },
    [getAllTransactions.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [getTransactionDetails.pending]: (state) => {
      state.loading = true;
    },
    [getTransactionDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.transactionDetails = action.payload;
      state.error = {};
    },
    [getTransactionDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [updateTransactionDetails.pending]: (state) => {
      state.loading = true;
    },
    [updateTransactionDetails.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.transactions;
      const modifiedTransactionList = {
        ...jsonState,
        transactions: jsonState.transactions?.map((i) =>
          i._id === action.payload._id ? action?.payload : i
        ),
      };

      state.loading = false;
      state.transactions = modifiedTransactionList;
      state.error = {};
    },
    [updateTransactionDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [deleteTransaction.pending]: (state) => {
      state.loading = true;
    },
    [deleteTransaction.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.transactions;
      const modifiedTransactionList = {
        ...jsonState,
        transactions: jsonState.transactions?.filter((transaction) => transaction._id !== action.payload),
      };
      state.loading = false;
      state.transactions = modifiedTransactionList;
      state.error = {};
    },
    [deleteTransaction.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
  },
});
export const { clearStaffError, clearTransactionDetails } = transactionSlice.actions;

export default transactionSlice.reducer;
