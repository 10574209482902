import { createAsyncThunk } from '@reduxjs/toolkit';

import { del, get, put, post } from './http';
import { activeSnack } from '../store/common';
import { getRentalDetails, getCustomerBalance } from './rental';

export const createTransaction = createAsyncThunk(
  'transaction/create',
  async ({ state, handleClose, dispatch, customerId, rentalId }) => {
    try {
      const URL = `/transaction/admin/new`;
      const URL_CUSTOMER = `/transaction/admin/new/${customerId}`;
      const response = await post(customerId ? URL_CUSTOMER : URL, state);
      if (response) {
        if (handleClose) {
          handleClose();
        }
        const cred = {
          limit: localStorage.getItem(`rowsPerPage_transaction`),
          dispatch,
        };
        dispatch(getAllTransactions(cred));
        if (rentalId) {
          const cred2 = {
            rentalId: response?.rental || response?.rental?._id,
            dispatch,
          };
          dispatch(getRentalDetails(cred2));
          dispatch(getCustomerBalance(cred2));
        }
        dispatch(activeSnack({ type: 'success', message: 'Transaction created successfully' }));
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getAllTransactions = createAsyncThunk(
  'transaction/list',
  async ({ page, search, dispatch, limit, sort, customer, vehicle, rental, dateValue }) => {
    try {
      const response = await get(
        `/transaction/admin/all?page=${page || 1}&search=${search || ''}&limit=${limit || 5}&sort=${
          sort || ''
        }&customer=${customer || ''}&vehicle=${vehicle || ''}&rental=${rental || ''}&start_date=${
          (dateValue && dateValue?.start_date) || ''
        }&end_date=${(dateValue && dateValue?.end_date) || ''}`
      );
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getTransactionDetails = createAsyncThunk(
  'transaction/details',
  async ({ transactionId, dispatch }) => {
    try {
      const response = await get(`/transaction/admin/${transactionId || ''}`);
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const updateTransactionDetails = createAsyncThunk(
  'transaction/update',
  async ({ state, transactionId, handleClose, dispatch, rentalId }) => {
    try {
      const URL = `/transaction/admin/${transactionId}`;

      const response = await put(URL, state);
      if (response) {
        if (handleClose) {
          handleClose();
        }
        const cred = {
          limit: localStorage.getItem(`rowsPerPage_transaction`),
          sort: response?.status,
          dispatch,
        };
        dispatch(getAllTransactions(cred));
        if (rentalId) {
          const cred2 = {
            rentalId: response?.rental?._id,
            dispatch,
          };
          dispatch(getRentalDetails(cred2));
          dispatch(getCustomerBalance(cred2));
        }
        dispatch(activeSnack({ type: 'success', message: 'Transaction updated Successfully' }));
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const deleteTransaction = createAsyncThunk(
  'transaction/delete',
  async ({ transactionId, dispatch, handleCloseDeleteDialog }) => {
    try {
      const response = await del(`/transaction/admin/${transactionId}/`);
      if (response) {
        if (handleCloseDeleteDialog) {
          handleCloseDeleteDialog();
        }
        const cred = {
          limit: localStorage.getItem(`rowsPerPage_transaction`),
          dispatch,
        };
        dispatch(getAllTransactions(cred));
        dispatch(activeSnack({ type: 'success', message: 'Transaction deleted Successfully' }));
        return response?._id;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
