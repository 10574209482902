import { createSlice } from '@reduxjs/toolkit';

import { covertToJSON } from '../../utils/stateToJson';
import {
  createBooking,
  deleteBooking,
  getAllBookings,
  getBookingDetails,
  updateBookingDetails,
} from '../api/booking';

export const bookingSlice = createSlice({
  name: 'booking',
  initialState: {
    loading: false,
    error: {},
    // staff
    bookings: {},
    bookingDetails: {},
  },
  reducers: {
    clearStaffError: (state) => {
      state.error = true;
    },
    clearBookingDetails: (state) => {
      state.bookingDetails = {};
    },
  },
  extraReducers: {
    [createBooking.pending]: (state) => {
      state.loading = true;
    },
    [createBooking.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.bookings;
      const newBookingList = jsonState?.bookings;

      const modifiedBookingList = {
        ...jsonState,
        bookings: Array.isArray(newBookingList)
          ? [action.payload, ...newBookingList]
          : [action.payload],
      };
      state.loading = false;
      state.bookings = modifiedBookingList;
      state.error = {};
    },
    [createBooking.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [getAllBookings.pending]: (state) => {
      state.loading = true;
    },
    [getAllBookings.fulfilled]: (state, action) => {
      state.loading = false;
      state.bookings = action.payload;
      state.error = {};
    },
    [getAllBookings.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [getBookingDetails.pending]: (state) => {
      state.loading = true;
    },
    [getBookingDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.bookingDetails = action.payload;
      state.error = {};
    },
    [getBookingDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [updateBookingDetails.pending]: (state) => {
      state.loading = true;
    },
    [updateBookingDetails.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.bookings;
      const modifiedBookingList = {
        ...jsonState,
        bookings: jsonState.bookings?.map((i) =>
          i._id === action.payload._id ? action?.payload : i
        ),
      };

      state.loading = false;
      state.bookings = modifiedBookingList;
      state.bookingDetails = action.payload
      state.error = {};
    },
    [updateBookingDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [deleteBooking.pending]: (state) => {
      state.loading = true;
    },
    [deleteBooking.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.bookings;
      const modifiedBookingList = {
        ...jsonState,
        bookings: jsonState.bookings?.filter((booking) => booking._id !== action.payload),
      };
      state.loading = false;
      state.bookings = modifiedBookingList;
      state.error = {};
    },
    [deleteBooking.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
  },
});
export const { clearStaffError, clearBookingDetails } = bookingSlice.actions;

export default bookingSlice.reducer;
