import { createAsyncThunk } from '@reduxjs/toolkit';

import { del, get, put, post } from './http';
import { activeSnack } from '../store/common';

export const createBank = createAsyncThunk(
  'bank/create',
  async ({ state, handleClose, dispatch }) => {
    try {
      const URL = `/bank/admin/new`;
      const response = await post(URL, state);
      if (response) {
        if (handleClose) {
          handleClose();
        }
        const cred = {
          limit: localStorage.getItem(`rowsPerPage_bank`),
          dispatch,
        };
        dispatch(getAllBanks(cred));
        dispatch(activeSnack({ type: 'success', message: 'Bank created successfully' }));
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getAllBanks = createAsyncThunk(
  'bank/list',
  async ({ page, search, dispatch, limit, sort, dateValue }) => {
    try {
      const response = await get(
        `/bank/admin/all?page=${page || 1}&search=${search || ''}&limit=${limit || 5}&sort=${
          sort || ''
        }&start_date=${dateValue?.start_date || ''}&end_date=${dateValue?.end_date || ''}`
      );
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getBankDetails = createAsyncThunk('bank/details', async ({ bankId, dispatch }) => {
  try {
    const response = await get(`/bank/admin/${bankId || ''}`);
    if (response) {
      return response;
    }
    return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
  } catch (error) {
    dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
    throw error?.response?.data?.message;
  }
});

export const updateBankDetails = createAsyncThunk(
  'bank/update',
  async ({ state, bankId, handleClose, dispatch }) => {
    try {
      const URL = `/bank/admin/${bankId}`;

      const response = await put(URL, state);
      if (response) {
        if (handleClose) {
          handleClose();
        }
        const cred = {
          limit: localStorage.getItem(`rowsPerPage_bank`),
          dispatch,
        };
        dispatch(getAllBanks(cred));
        dispatch(activeSnack({ type: 'success', message: 'Bank updated Successfully' }));
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const deleteBank = createAsyncThunk(
  'bank/delete',
  async ({ bankId, dispatch, handleCloseDeleteDialog }) => {
    try {
      const response = await del(`/bank/admin/${bankId}/`);
      if (response) {
        if (handleCloseDeleteDialog) {
          handleCloseDeleteDialog();
        }
        const cred = {
          limit: localStorage.getItem(`rowsPerPage_bank`),
          dispatch,
        };
        dispatch(getAllBanks(cred));
        dispatch(activeSnack({ type: 'success', message: 'Bank deleted Successfully' }));
        return response?._id;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getBankExcelData = createAsyncThunk(
  'bank/download',
  async ({ search, dispatch, sort, dateValue }) => {
    try {
      const response = await get(
        `/bank/admin/excel/print?search=${search || ''}&sort=${sort || ''}&start_date=${
          dateValue?.start_date || ''
        }&end_date=${dateValue?.end_date || ''}`
      );
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
