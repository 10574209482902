import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// @mui
import {
  Box,
  Card,
  Grid,
  Stack,
  Paper,
  Table,
  Button,
  TableBody,
  TableCell,
  TextField,
  Container,
  Autocomplete,
  TableContainer,
  CircularProgress,
} from '@mui/material';

import { paths } from 'src/routes/routes/paths';

import { getAllKanthooras } from 'src/server/api/kanthoora';
import { deleteModel, getAllModels } from 'src/server/api/model';

// components
import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs/custom-breadcrumbs';
import {
  useTable,
  TableNoData,
  TableHeadCustom,
  TableSelectedAction,
  TablePaginationCustom,
} from 'src/components/table';

import ModelCrud from 'src/sections/Model/ModelCrud';
import ModelSearch from 'src/sections/Model/ModelSearch';
import ModelTableRow from 'src/sections/Model/ModelTableRow';
// sections
import DeleteConfirmDialog from 'src/sections/common/DeleteConfirm';

export default function Model() {
  const dispatch = useDispatch();
  const table = useTable();

  const { models, loading, kanthooras } = useSelector((state) => ({
    models: state.model.models,
    kanthooras: state.kanthoora.kanthooras,
    loading: state.model.loading,
  }));

  const item = {
    heading: 'Are you sure you want to delete this model? This action cannot be undone.',
  };

  const [openModelCrudDialog, setOpenModelCrudDialog] = useState(false);
  const [modelData, setModelData] = useState({});
  const [selectedKanthoora, setSelectedKanthoora] = useState({});
  const [searchKanthoora, setSearchKanthoora] = useState('');
  const [openDeleteConfirmDialog, setOpenDeleteConfirmDialog] = useState(false);
  const [tableData, setTableData] = useState([]);
  const canReset = false;
  const notFound = (!tableData?.length && canReset) || !tableData?.length;

  const [searchText, setSearchText] = useState('');
  const total = models?.total;
  const identifier = 'model';
  const storedRowsPerPage = localStorage.getItem(`rowsPerPage_${identifier}`);

  const TABLE_HEAD = [
    { id: 'date', label: 'Date' },
    { id: 'name', label: 'Name' },
    { id: 'kanthoora', label: 'Kanthoora' },
    { id: 'description', label: 'Description' },
    { id: 'Image', label: 'Image' },
    { id: '', label: '' },
  ];

  useEffect(() => {
    setTableData(models?.models);
  }, [models]);

  const handleCloseDeleteDialog = () => {
    setOpenDeleteConfirmDialog(false);
  };

  const handleDeleteClick = (model) => {
    setModelData(model);
    setOpenDeleteConfirmDialog(true);
  };

  const handleDeleteConfirmClick = () => {
    const credentials = {
      dispatch,
      modelId: modelData?._id,
      handleCloseDeleteDialog,
    };
    dispatch(deleteModel(credentials));
  };

  const handleEditRow = (model) => {
    if (model) {
      setModelData(model);
    }
    setOpenModelCrudDialog(true);
  };

  const handleViewRow = (id) => {
    console.log('id', id);
  };

  const handleSearch = (value) => {
    setSearchText(value);
  };

  useEffect(() => {
    const credentials = {
      page: table.page + 1,
      search: searchText,
      dispatch,
      limit: table.rowsPerPage,
      kanthoora: selectedKanthoora?.value,
    };
    dispatch(getAllModels(credentials));
  }, [dispatch, searchText, table.page, table.rowsPerPage, selectedKanthoora?.value]);

  const customerOptions = [
    {
      label: 'All',
      value: '',
    },
    ...(kanthooras?.kanthooras || []).map((result) => ({
      label: result?.name,
      value: result?._id,
    })),
  ];

  const handleAutocompleteChangeKanthoora = (_, newValue) => {
    setSelectedKanthoora({
      value: newValue?.value || '',
      label: newValue?.label || '',
    });
  };

  const handleSearchKanthoora = (event) => {
    setSearchKanthoora(event.target.value);
  };

  useEffect(() => {
    const credentials = {
      page: 1,
      search: searchKanthoora,
      dispatch,
      limit: 10,
    };
    dispatch(getAllKanthooras(credentials));
  }, [dispatch, searchKanthoora]);

  return (
    <>
      {openModelCrudDialog && (
        <ModelCrud
          setOpen={setOpenModelCrudDialog}
          open={openModelCrudDialog}
          isUpdate={modelData}
          setModelData={setModelData}
        />
      )}

      <DeleteConfirmDialog
        onConfirmClick={handleDeleteConfirmClick}
        setOpen={setOpenDeleteConfirmDialog}
        open={openDeleteConfirmDialog}
        item={item}
        loading={loading}
        title={`Model "${modelData?.name}"`}
      />

      <Container maxWidth="xl">
        <CustomBreadcrumbs
          heading="Model"
          links={[
            {
              name: 'Dashboard',
              href: paths.dashboard.root,
            },

            {
              name: 'Model',
            },
          ]}
          action={
            <Stack flexDirection="row" spacing={2}>
              <Button
                onClick={() => setOpenModelCrudDialog(true)}
                variant="contained"
                color="inherit"
                startIcon={<Iconify icon="mingcute:add-line" />}
              >
                New Model
              </Button>
            </Stack>
          }
          sx={{
            mb: { xs: 3, md: 5 },
          }}
        />
        <Grid container spacing={1} item>
          <Grid item xl={8} lg={10} md={12}>
            <Card>
              <Stack direction="row" spacing={2} p={3}>
                <ModelSearch onSearch={handleSearch} />
                <Autocomplete
                  id="country-select-demo"
                  sx={{ width: '30%' }}
                  options={customerOptions}
                  autoHighlight
                  fullWidth
                  getOptionLabel={(option) => option.label}
                  onChange={handleAutocompleteChangeKanthoora}
                  renderOption={(props, option) => (
                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                      {option.label}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Kanthoora"
                      onChange={handleSearchKanthoora}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password', // disable autocomplete and autofill
                      }}
                    />
                  )}
                />
              </Stack>
              <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
                <TableSelectedAction
                  dense={table.dense}
                  numSelected={table.selected?.length}
                  rowCount={tableData?.length}
                  onSelectAllRows={(checked) => {
                    table.onSelectAllRows(
                      checked,
                      tableData.map((row) => row.id)
                    );
                  }}
                />
                <Scrollbar>
                  <Table size={table.dense ? 'small' : 'medium'}>
                    <TableHeadCustom
                      order={table.order}
                      orderBy={table.orderBy}
                      headLabel={TABLE_HEAD}
                      rowCount={tableData?.length}
                      numSelected={table.selected?.length}
                      onSort={table.onSort}
                    />

                    <TableBody>
                      {loading ? (
                        <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                          <Paper
                            sx={{
                              textAlign: 'center',
                            }}
                          >
                            <CircularProgress color="inherit" />
                          </Paper>
                        </TableCell>
                      ) : (
                        <>
                          {(tableData || []).map((row, index) => (
                            <ModelTableRow
                              index={index}
                              key={row.id}
                              row={row}
                              onViewRow={() => handleViewRow(row._id)}
                              onEditRow={() => handleEditRow(row)}
                              onDeleteRow={() => handleDeleteClick(row)}
                            />
                          ))}
                        </>
                      )}
                    </TableBody>
                    <TableNoData notFound={notFound} />
                  </Table>
                </Scrollbar>
              </TableContainer>

              <TablePaginationCustom
                count={total || 0}
                page={table.page}
                rowsPerPage={table.rowsPerPage || storedRowsPerPage || 5}
                onPageChange={table.onChangePage}
                onRowsPerPageChange={table.onChangeRowsPerPage}
                dense={table.dense}
                onChangeDense={table.onChangeDense}
              />
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
