import { createAsyncThunk } from '@reduxjs/toolkit';

import { paths } from 'src/routes/routes/paths';

import { del, get, put, post } from './http';
import { activeSnack } from '../store/common';

export const createExpense = createAsyncThunk(
  'expense/create',
  async ({ state, navigate, dispatch }) => {
    try {
      const URL = `/expense/admin/bulk/new`;
      const response = await post(URL, state);
      if (response) {
        if (navigate) {
          navigate(paths.dashboard.expense);
        }
        dispatch(activeSnack({ type: 'success', message: 'Expense created successfully' }));
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getAllExpenses = createAsyncThunk(
  'expense/list',
  async ({ page, search, dispatch, limit, sort, customer, vehicle, dateValue, expenseType }) => {
    try {
      const response = await get(
        `/expense/admin/all?page=${page || 1}&search=${search || ''}&limit=${limit || 5}&sort=${
          sort || ''
        }&vehicle=${vehicle || ''}&customer=${customer || ''}&start_date=${
          (dateValue && dateValue?.start_date) || ''
        }&end_date=${(dateValue && dateValue?.end_date) || ''}&type=${expenseType || ''}`
      );
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getExpenseDetails = createAsyncThunk(
  'expense/details',
  async ({ expenseId, dispatch }) => {
    try {
      const response = await get(`/expense/admin/${expenseId || ''}`);
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const updateExpenseDetails = createAsyncThunk(
  'expense/update',
  async ({ state, expenseId, handleClose, dispatch }) => {
    try {
      const URL = `/expense/admin/${expenseId}`;

      const response = await put(URL, state);
      if (response) {
        if (handleClose) {
          handleClose();
        }
        dispatch(activeSnack({ type: 'success', message: 'Expense updated Successfully' }));
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const deleteExpense = createAsyncThunk(
  'expense/delete',
  async ({ expenseId, dispatch, handleCloseDeleteDialog }) => {
    try {
      const response = await del(`/expense/admin/${expenseId}/`);
      if (response) {
        if (handleCloseDeleteDialog) {
          handleCloseDeleteDialog();
        }
        const cred = {
          limit: localStorage.getItem(`rowsPerPage_expense`),
          dispatch,
        };
        dispatch(getAllExpenses(cred));
        dispatch(activeSnack({ type: 'success', message: 'Expense deleted Successfully' }));
        return response?._id;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getExpenseExcelData = createAsyncThunk(
  'expense/download',
  async ({ search, dispatch, sort, customer, vehicle, dateValue, expenseType }) => {
    try {
      const response = await get(
        `/expense/admin/excel/print?search=${search || ''}&sort=${sort || ''}&vehicle=${
          vehicle || ''
        }&customer=${customer || ''}&start_date=${
          (dateValue && dateValue?.start_date) || ''
        }&end_date=${(dateValue && dateValue?.end_date) || ''}&type=${expenseType || ''}`
      );
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
