import { createSlice } from '@reduxjs/toolkit';

import { covertToJSON } from '../../utils/stateToJson';
import {
  createTailor,
  deleteTailor,
  getAllTailors,
  getTailorDetails,
  updateTailorDetails,
} from '../api/tailor';

export const tailorSlice = createSlice({
  name: 'tailor',
  initialState: {
    loading: false,
    error: {},
    // staff
    tailors: {},
  },
  reducers: {
    clearStaffError: (state) => {
      state.error = true;
    },
  },
  extraReducers: {
    [createTailor.pending]: (state) => {
      state.loading = true;
    },
    [createTailor.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.tailors;
      const newTailorList = jsonState?.tailors;

      const modifiedTailorList = {
        ...jsonState,
        tailors: Array.isArray(newTailorList)
          ? [action.payload, ...newTailorList]
          : [action.payload],
      };
      state.loading = false;
      state.tailors = modifiedTailorList;
      state.error = {};
    },
    [createTailor.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [getAllTailors.pending]: (state) => {
      state.loading = true;
    },
    [getAllTailors.fulfilled]: (state, action) => {
      state.loading = false;
      state.tailors = action.payload;
      state.error = {};
    },
    [getAllTailors.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [getTailorDetails.pending]: (state) => {
      state.loading = true;
    },
    [getTailorDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.bookingDetails = action.payload;
      state.error = {};
    },
    [getTailorDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [updateTailorDetails.pending]: (state) => {
      state.loading = true;
    },
    [updateTailorDetails.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.tailors;
      const modifiedTailorList = {
        ...jsonState,
        tailors: jsonState.tailors?.map((i) =>
          i._id === action.payload._id ? action?.payload : i
        ),
      };

      state.loading = false;
      state.tailors = modifiedTailorList;
      state.error = {};
    },
    [updateTailorDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [deleteTailor.pending]: (state) => {
      state.loading = true;
    },
    [deleteTailor.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.tailors;
      const modifiedTailorList = {
        ...jsonState,
        tailors: jsonState.tailors?.filter((tailor) => tailor._id !== action.payload),
      };
      state.loading = false;
      state.tailors = modifiedTailorList;
      state.error = {};
    },
    [deleteTailor.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
  },
});
export const { clearStaffError } = tailorSlice.actions;

export default tailorSlice.reducer;
