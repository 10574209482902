import PropTypes from 'prop-types';

import Pagination from '@mui/material/Pagination';
// @mui
import {
  Box,
  Card,
  Stack,
  Tooltip,
  CardMedia,
  Typography,
  IconButton,
  ListItemText,
} from '@mui/material';

import { fDate } from 'src/utils/format-time';

import Iconify from 'src/components/iconify';
import { useLightBox } from 'src/components/lightbox';
import Lightbox from 'src/components/lightbox/lightbox';
import { usePopover } from 'src/components/custom-popover';

import noVehicleImage from '../../assets/other/no_image.jpg';

ModelCategoryData.propTypes = {
  modelCategoryData: PropTypes.object,
  page: PropTypes.any,
  setPage: PropTypes.any,
  total: PropTypes.any,
  handleEditClick: PropTypes.any,
  handleDeleteClick: PropTypes.any,
};

export default function ModelCategoryData({
  modelCategoryData,
  page,
  setPage,
  total,
  handleEditClick,
  handleDeleteClick,
}) {
  const handleChange = (event, value) => {
    setPage(value);
  };
  return (
    <>
      <Box
        gap={3}
        display="grid"
        gridTemplateColumns={{
          xs: 'repeat(1, 1fr)',
          sm: 'repeat(3, 1fr)',
          md: 'repeat(4, 1fr)',
          xl: 'repeat(5, 1fr)',
        }}
      >
        {modelCategoryData?.modelcategories?.map((modelCategory, key) => (
          <ModelCard
            key={key}
            modelCategory={modelCategory}
            handleEditClick={handleEditClick}
            handleDeleteClick={handleDeleteClick}
          />
        ))}
      </Box>
      {modelCategoryData?.total > 10 && (
        <Stack direction="row" justifyContent="center" mt={8}>
          <Pagination
            count={total}
            page={page}
            onChange={handleChange}
            className="custom-pagination"
          />
        </Stack>
      )}
    </>
  );
}

function ModelCard({ modelCategory, handleEditClick, handleDeleteClick }) {
  const { createdAt, name, images, model, kanthoora } = modelCategory;

  const popover = usePopover();

  const slides = images?.map((img) => ({
    src: img?.url,
  }));

  const lightbox = useLightBox(slides);

  const handleDelete = () => {
    popover.onClose();
    handleDeleteClick(modelCategory);
  };

  const handleEdit = () => {
    popover.onClose();
    handleEditClick(modelCategory);
  };

  return (
    <>
      <Lightbox
        index={lightbox.selected}
        slides={slides}
        open={lightbox.open}
        close={lightbox.onClose}
        onGetCurrentIndex={(index) => lightbox.setSelected(index)}
      />
      <Card
        sx={{
          color: 'text.disabled',
          '&:hover': {
            backgroundColor: '#d7dde3',
            color: '#000000B8',
          },
        }}
        // onClick={handleCardClick}
      >
        <Stack sx={{ p: 1, pb: 2 }}>
          {images && images?.length > 0 ? (
            <Tooltip title="Click to view image">
              <CardMedia
                component="img"
                src={images[0]?.url}
                alt=""
                sx={{ borderRadius: '10px', cursor: 'pointer' }}
                height="200px"
                onClick={() => lightbox.onOpen(images[0]?.url)}
              />
            </Tooltip>
          ) : (
            <CardMedia
              component="img"
              src={noVehicleImage}
              alt=""
              sx={{ borderRadius: '10px' }}
              height="200px"
            />
          )}
          <ListItemText
            sx={{ mb: 1 }}
            primary={`Added date: ${fDate(createdAt)}`}
            secondary={name}
            primaryTypographyProps={{
              pt: 2,
              pl: 2,
              component: 'span',
              typography: 'caption',
              // color: 'text.disabled',
            }}
            secondaryTypographyProps={{
              typography: 'subtitle1',
              pt: 1,
              pl: 2,
              color: 'black',
            }}
          />
          <Stack direction="row" justifyContent="space-between" mx={1} mt={1} sx={{ minWidth: 0 }}>
            <Typography variant="caption" noWrap pl={1}>
              Model
            </Typography>
            <Typography fontSize={13} fontWeight={400} color="#212B36">
              {model?.name || '-----'}
            </Typography>
          </Stack>
          <Stack direction="row" mx={1} justifyContent="space-between" mt={1} sx={{ minWidth: 0 }}>
            <Typography variant="caption" noWrap pl={1}>
              Kanthoora
            </Typography>
            <Typography fontSize={13} fontWeight={400} color="#212B36">
              {kanthoora?.name || '-----'}
            </Typography>
          </Stack>
          <Stack direction="row" spacing={0} justifyContent="end" mt={2}>
            <IconButton color="info" onClick={handleEdit}>
              <Iconify icon="solar:pen-bold" />
            </IconButton>
            <IconButton color="error" onClick={handleDelete}>
              <Iconify icon="solar:trash-bin-trash-bold" />
            </IconButton>
          </Stack>
        </Stack>
      </Card>
    </>
  );
}

ModelCard.propTypes = {
  modelCategory: PropTypes.object,
  handleEditClick: PropTypes.any,
  handleDeleteClick: PropTypes.any,
};
