import * as React from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';

function Media() {
  return (
    <Grid container wrap="nowrap">
      {Array.from(new Array(4)).map((item, index) => (
        <Box key={index} sx={{ width: '100%', marginRight: 1, my: 0.5, borderRadius: 2 }}>
          <Skeleton variant="rectangular" width="100%" height={200} sx={{ borderRadius: 2 }} />
          {/* <Box sx={{ pt: 0.5 }}>
            <Skeleton />
            <Skeleton width="60%" />
          </Box> */}
        </Box>
      ))}
    </Grid>
  );
}

export default function DashboardLoading() {
  return (
    <Box sx={{ overflow: 'hidden' }}>
      <Media />
      <Media />
      <Media />
    </Box>
  );
}
