import { createAsyncThunk } from '@reduxjs/toolkit';

import { paths } from 'src/routes/routes/paths';

import { del, get, put, post } from './http';
import { activeSnack } from '../store/common';

export const createIncome = createAsyncThunk(
  'income/create',
  async ({ state, navigate, dispatch }) => {
    try {
      const URL = `/income/admin/bulk/new`;
      const response = await post(URL, state);
      if (response) {
        if (navigate) {
          navigate(paths.dashboard.income);
        }
        dispatch(activeSnack({ type: 'success', message: 'Income created successfully' }));
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getAllIncomes = createAsyncThunk(
  'income/list',
  async ({ page, search, dispatch, limit, sort, rental, vehicle, dateValue, category }) => {
    try {
      const response = await get(
        `/income/admin/all?page=${page || 1}&search=${search || ''}&limit=${limit || 5}&sort=${
          sort || ''
        }&vehicle=${vehicle || ''}&rental=${rental || ''}&start_date=${
          dateValue?.start_date || ''
        }&end_date=${dateValue?.end_date || ''}&category=${category || ''}`
      );
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getIncomeDetails = createAsyncThunk(
  'income/details',
  async ({ incomeId, dispatch }) => {
    try {
      const response = await get(`/income/admin/${incomeId || ''}`);
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const updateIncomeDetails = createAsyncThunk(
  'income/update',
  async ({ state, incomeId, handleClose, dispatch }) => {
    try {
      const URL = `/income/admin/${incomeId}`;

      const response = await put(URL, state);
      if (response) {
        if (handleClose) {
          handleClose();
        }
        dispatch(activeSnack({ type: 'success', message: 'Income updated Successfully' }));
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const deleteIncome = createAsyncThunk(
  'income/delete',
  async ({ incomeId, dispatch, handleCloseDeleteDialog }) => {
    try {
      const response = await del(`/income/admin/${incomeId}/`);
      if (response) {
        if (handleCloseDeleteDialog) {
          handleCloseDeleteDialog();
        }
        const cred = {
          limit: localStorage.getItem(`rowsPerPage_income`),
          dispatch,
        };
        dispatch(getAllIncomes(cred));
        dispatch(activeSnack({ type: 'success', message: 'Income deleted Successfully' }));
        return response?._id;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getIncomeExcelData = createAsyncThunk(
  'income/download',
  async ({ search, dispatch, sort, rental, vehicle, dateValue, category }) => {
    try {
      const response = await get(
        `/income/admin/excel/print?search=${search || ''}&sort=${sort || ''}&vehicle=${
          vehicle || ''
        }&rental=${rental || ''}&start_date=${dateValue?.start_date || ''}&end_date=${
          dateValue?.end_date || ''
        }&category=${category || ''}`
      );
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
