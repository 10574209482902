import { createAsyncThunk } from '@reduxjs/toolkit';

import { del, get, put, post } from './http';
import { activeSnack } from '../store/common';

export const createCloth = createAsyncThunk(
  'cloth/create',
  async ({ state, dispatch, handleClose, handleClear }) => {
    try {
      const URL = `/cloth/admin/new`;
      const response = await post(URL, state);
      if (response) {
        handleClose();
        handleClear();
        const cred = {
          page: 1,
          limit: 10,
          dispatch,
        };
        dispatch(getAllCloths(cred));
        dispatch(activeSnack({ type: 'success', message: 'Cloth created successfully' }));
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getAllCloths = createAsyncThunk(
  'cloth/list',
  async ({ page, search, dispatch, limit, brand }) => {
    try {
      const response = await get(
        `/cloth/admin/all?page=${page || 1}&search=${search || ''}&limit=${limit || 5}&brand=${brand || ''}`
      );
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getClothDetails = createAsyncThunk('cloth/details', async ({ clothId, dispatch }) => {
  try {
    const response = await get(`/cloth/admin/${clothId || ''}`);
    if (response) {
      return response;
    }
    return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
  } catch (error) {
    dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
    throw error?.response?.data?.message;
  }
});

export const updateClothDetails = createAsyncThunk(
  'cloth/update',
  async ({ state, clothId, dispatch, handleClose }) => {
    try {
      const URL = `/cloth/admin/${clothId}`;

      const response = await put(URL, state);
      if (response) {
        if (handleClose) {
          handleClose();
        }
        const cred = {
          page: 1,
          limit: 10,
          dispatch,
        };
        dispatch(getAllCloths(cred));
        dispatch(activeSnack({ type: 'success', message: 'Cloth updated Successfully' }));
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const deleteCloth = createAsyncThunk(
  'cloth/delete',
  async ({ clothId, dispatch, handleCloseDeleteDialog }) => {
    try {
      const response = await del(`/cloth/admin/${clothId}/`);
      if (response) {
        handleCloseDeleteDialog();
        dispatch(activeSnack({ type: 'success', message: 'Cloth deleted Successfully' }));
        return response?._id;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
