import { MetaHelmet } from 'src/layouts/Helmet/Helmet';

import Kanthoora from './kanthooraPage';

export default function AllKanthooras() {
  return (
    <>
      <MetaHelmet title="Kanthoora" />
      <Kanthoora />
    </>
  );
}
