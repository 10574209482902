import { configureStore } from '@reduxjs/toolkit';

import appReducer from './app';
import authReducer from './auth';
import bankReducer from './bank';
import shopReducer from './shop';
import brandReducer from './brand';
import clothReducer from './cloth';
import modelReducer from './model';
import commonReducer from './common';
import rentalReducer from './rental';
import incomeReducer from './income';
import tailorReducer from './tailor';
import CountryReducer from './country';
import vehicleReducer from './vehicle';
import bookingReducer from './booking';
import expenseReducer from './expense';
import customerReducer from './customer';
import settingsReducer from './settings';
import dashboardReducer from './dashboard';
import kanthooraReducer from './kanthoora';
import transactionReducer from './transaction';
import balanceSheetReducer from './balanceSheet';
import modelCategoryReducer from './modelCategory';

export default configureStore({
  reducer: {
    common: commonReducer,
    auth: authReducer,
    app: appReducer,
    dashboard: dashboardReducer,
    country: CountryReducer,
    brand: brandReducer,
    vehicle: vehicleReducer,
    customer: customerReducer,
    booking: bookingReducer,
    rental: rentalReducer,
    transaction: transactionReducer,
    bank: bankReducer,
    expense: expenseReducer,
    income: incomeReducer,
    balanceSheet: balanceSheetReducer,
    shop: shopReducer,
    modelCategory: modelCategoryReducer,
    model: modelReducer,
    cloth: clothReducer,
    kanthoora: kanthooraReducer,
    tailor: tailorReducer,
    settings: settingsReducer,
  },
});
