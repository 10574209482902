import { createAsyncThunk } from '@reduxjs/toolkit';

import { del, get, put, post } from './http';
import { activeSnack } from '../store/common';

export const createModel = createAsyncThunk(
  'model/create',
  async ({ state, dispatch, handleClose, handleClear }) => {
    try {
      const URL = `/model/admin/new`;
      const response = await post(URL, state);
      if (response) {
        handleClose();
        handleClear();
        const cred = {
          page: 1,
          limit: 10,
          dispatch,
        };
        dispatch(getAllModels(cred));
        dispatch(activeSnack({ type: 'success', message: 'Model created successfully' }));
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getAllModels = createAsyncThunk(
  'model/list',
  async ({ page, search, dispatch, limit, kanthoora }) => {
    try {
      const response = await get(
        `/model/admin/all?page=${page || 1}&search=${search || ''}&limit=${limit || 5}&kanthoora=${kanthoora || ''}`
      );
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getModelDetails = createAsyncThunk('model/details', async ({ modelId, dispatch }) => {
  try {
    const response = await get(`/model/admin/${modelId || ''}`);
    if (response) {
      return response;
    }
    return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
  } catch (error) {
    dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
    throw error?.response?.data?.message;
  }
});

export const updateModelDetails = createAsyncThunk(
  'model/update',
  async ({ state, modelId, dispatch, handleClose }) => {
    try {
      const URL = `/model/admin/${modelId}`;

      const response = await put(URL, state);
      if (response) {
        if (handleClose) {
          handleClose();
        }
        const cred = {
          page: 1,
          limit: 10,
          dispatch,
        };
        dispatch(getAllModels(cred));
        dispatch(activeSnack({ type: 'success', message: 'Model updated Successfully' }));
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const deleteModel = createAsyncThunk(
  'model/delete',
  async ({ modelId, dispatch, handleCloseDeleteDialog }) => {
    try {
      const response = await del(`/model/admin/${modelId}/`);
      if (response) {
        handleCloseDeleteDialog();
        dispatch(activeSnack({ type: 'success', message: 'Model deleted Successfully' }));
        return response?._id;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
