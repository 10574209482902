import PropTypes from 'prop-types';

import Pagination from '@mui/material/Pagination';
// @mui
import { Box, Card, Stack, MenuItem, Typography, IconButton } from '@mui/material';

import Image from 'src/components/image';
import Iconify from 'src/components/iconify';
import { usePopover } from 'src/components/custom-popover';
import CustomPopover from 'src/components/custom-popover/custom-popover';

import noVehicleImage from '../../assets/other/no_image.jpg';

KanthoorahData.propTypes = {
  kanthooraData: PropTypes.object,
  page: PropTypes.any,
  setPage: PropTypes.any,
  total: PropTypes.any,
  handleEditClick: PropTypes.any,
  handleDeleteClick: PropTypes.any,
};

export default function KanthoorahData({
  kanthooraData,
  page,
  setPage,
  total,
  handleEditClick,
  handleDeleteClick,
}) {
  const handleChange = (event, value) => {
    setPage(value);
  };
  return (
    <>
      <Box
        gap={3}
        display="grid"
        gridTemplateColumns={{
          xs: 'repeat(1, 1fr)',
          sm: 'repeat(2, 1fr)',
          md: 'repeat(4, 1fr)',
        }}
      >
        {kanthooraData?.kanthooras?.map((kanthoora, key) => (
          <KanthooraCard
            key={key}
            kanthoora={kanthoora}
            handleEditClick={handleEditClick}
            handleDeleteClick={handleDeleteClick}
          />
        ))}
      </Box>
      {kanthooraData?.total > 12 && (
        <Stack direction="row" justifyContent="center" mt={8}>
          <Pagination
            count={total}
            page={page}
            onChange={handleChange}
            className="custom-pagination"
          />
        </Stack>
      )}
    </>
  );
}

function KanthooraCard({ kanthoora, handleEditClick, handleDeleteClick }) {
  const { name, images } = kanthoora;

  const popover = usePopover();

  const handleDelete = (kanthooraData) => {
    popover.onClose();
    handleDeleteClick(kanthooraData);
  };

  const handleEdit = (kanthooraData) => {
    popover.onClose();
    handleEditClick(kanthooraData);
  };

  return (
    <>
      <Card>
        <Stack direction="row" justifyContent="space-between" alignItems="center" mx={1} py={1}>
          <Typography variant="subtitle1" color="text.primary">
            {name}
          </Typography>
          <Box display="flex" flexDirection="column">
            <IconButton color="info" onClick={() => handleEdit(kanthoora)}>
              <Iconify icon="solar:pen-bold" />
            </IconButton>
            <IconButton color="error" onClick={() => handleDelete(kanthoora)}>
              <Iconify icon="solar:trash-bin-trash-bold" />
            </IconButton>
          </Box>
        </Stack>

        {images?.length > 0 ? (
          <Image src={images[0]?.url} alt={name} ratio="16/9" />
        ) : (
          <Image src={noVehicleImage} alt="" ratio="16/9" />
        )}

        {/* <IconButton
          color={popover.open ? 'inherit' : 'default'}
          onClick={popover.onOpen}
          sx={{ top: 8, right: 8, position: 'absolute' }}
        >
          <Iconify icon="eva:more-vertical-fill" />
        </IconButton> */}
      </Card>

      <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        arrow="right-top"
        sx={{ width: 140 }}
      >
        <MenuItem onClick={() => handleEdit(kanthoora)}>
          <Iconify icon="solar:pen-bold" />
          Edit
        </MenuItem>

        <MenuItem onClick={() => handleDelete(kanthoora)} sx={{ color: 'error.main' }}>
          <Iconify icon="solar:trash-bin-trash-bold" />
          Delete
        </MenuItem>
      </CustomPopover>
    </>
  );
}

KanthooraCard.propTypes = {
  kanthoora: PropTypes.object,
  handleEditClick: PropTypes.any,
  handleDeleteClick: PropTypes.any,
};
