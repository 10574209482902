import { createSlice } from '@reduxjs/toolkit';

import { covertToJSON } from '../../utils/stateToJson';
import {
  createKanthoora,
  deleteKanthoora,
  getAllKanthooras,
  getKanthooraDetails,
  updateKanthooraDetails,
} from '../api/kanthoora';

export const kanthooraSlice = createSlice({
  name: 'kanthoora',
  initialState: {
    loading: false,
    error: {},
    // staff
    kanthooras: {},
  },
  reducers: {
    clearStaffError: (state) => {
      state.error = true;
    },
  },
  extraReducers: {
    [createKanthoora.pending]: (state) => {
      state.loading = true;
    },
    [createKanthoora.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.kanthooras;
      const newKanthooraList = jsonState?.kanthooras;

      const modifiedKanthooraList = {
        ...jsonState,
        kanthooras: Array.isArray(newKanthooraList)
          ? [action.payload, ...newKanthooraList]
          : [action.payload],
      };
      state.loading = false;
      state.kanthooras = modifiedKanthooraList;
      state.error = {};
    },
    [createKanthoora.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [getAllKanthooras.pending]: (state) => {
      state.loading = true;
    },
    [getAllKanthooras.fulfilled]: (state, action) => {
      state.loading = false;
      state.kanthooras = action.payload;
      state.error = {};
    },
    [getAllKanthooras.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [getKanthooraDetails.pending]: (state) => {
      state.loading = true;
    },
    [getKanthooraDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.bookingDetails = action.payload;
      state.error = {};
    },
    [getKanthooraDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [updateKanthooraDetails.pending]: (state) => {
      state.loading = true;
    },
    [updateKanthooraDetails.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.kanthooras;
      const modifiedKanthooraList = {
        ...jsonState,
        kanthooras: jsonState.kanthooras?.map((i) =>
          i._id === action.payload._id ? action?.payload : i
        ),
      };

      state.loading = false;
      state.kanthooras = modifiedKanthooraList;
      state.error = {};
    },
    [updateKanthooraDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [deleteKanthoora.pending]: (state) => {
      state.loading = true;
    },
    [deleteKanthoora.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.kanthooras;
      const modifiedKanthooraList = {
        ...jsonState,
        kanthooras: jsonState.kanthooras?.filter((kanthoora) => kanthoora._id !== action.payload),
      };
      state.loading = false;
      state.kanthooras = modifiedKanthooraList;
      state.error = {};
    },
    [deleteKanthoora.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
  },
});
export const { clearStaffError } = kanthooraSlice.actions;

export default kanthooraSlice.reducer;
