import { createAsyncThunk } from '@reduxjs/toolkit';

import { paths } from 'src/routes/routes/paths';

import { del, get, put, post } from './http';
import { activeSnack } from '../store/common';

export const createCustomer = createAsyncThunk(
  'customer/create',
  async ({ state, navigate, dispatch }) => {
    try {
      const URL = `/customer/admin/new`;
      const response = await post(URL, state);
      if (response) {
        if (navigate) {
          navigate(paths.dashboard.customer);
        }
        dispatch(activeSnack({ type: 'success', message: 'Customer created successfully' }));
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getAllCustomers = createAsyncThunk(
  'customer/list',
  async ({ page, search, dispatch, limit, date, sort }) => {
    try {
      const response = await get(
        `/customer/admin/all?page=${page && page}&search=${search && search}&limit=${
          limit || 5
        }&start_date=${date?.start_date || ''}&end_date=${date?.end_date || ''}&sort=${sort || ""}`
      );
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getCustomerDetails = createAsyncThunk(
  'customer/details',
  async ({ customerId, dispatch }) => {
    try {
      const response = await get(`/customer/admin/${customerId || ''}`);
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const updateCustomerDetails = createAsyncThunk(
  'customer/update',
  async ({ state, customerId, navigate, dispatch }) => {
    try {
      const URL = `/customer/admin/${customerId}`;

      const response = await put(URL, state);
      if (response) {
        if (navigate) {
          navigate(paths.dashboard.customer_view(response?._id));
        }
        dispatch(activeSnack({ type: 'success', message: 'Customer updated Successfully' }));
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const deleteCustomer = createAsyncThunk(
  'customer/delete',
  async ({ customerId, dispatch, handleCloseDeleteDialog, navigate }) => {
    try {
      const response = await del(`/customer/admin/${customerId}/`);
      if (response) {
        if (navigate) {
          navigate(paths.dashboard.customer);
        }
        if (handleCloseDeleteDialog) {
          handleCloseDeleteDialog();
        }
        dispatch(activeSnack({ type: 'success', message: 'Customer deleted Successfully' }));
        return response?._id;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
