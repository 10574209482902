import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// @mui
import {
  Box,
  Stack,
  Paper,
  Button,
  TextField,
  Container,
  Typography,
  Autocomplete,
  CircularProgress,
} from '@mui/material';

import { paths } from 'src/routes/routes/paths';

import { useResponsive } from 'src/hooks/use-responsive';

import { getAllBrands } from 'src/server/api/brand';
import { deleteCloth, getAllCloths } from 'src/server/api/cloth';

// components
import Iconify from 'src/components/iconify';
import EmptyContent from 'src/components/empty-content/empty-content';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs/custom-breadcrumbs';

import ClothCrud from 'src/sections/Cloth/ClothCrud';
import ClothData from 'src/sections/Cloth/ClothData';
import ClothSearch from 'src/sections/Cloth/ClothSearch';
// sections
import DeleteConfirmDialog from 'src/sections/common/DeleteConfirm';

export default function Cloth() {
  const dispatch = useDispatch();
  const isMobile = useResponsive('down', 'sm');

  const { cloths, loading, brands } = useSelector((state) => ({
    cloths: state.cloth.cloths,
    brands: state.brand.brands,
    loading: state.cloth.loading,
  }));

  const item = {
    heading: 'Are you sure you want to delete this cloth? This action cannot be undone.',
  };

  const [openClothCrudDialog, setOpenClothCrudDialog] = useState(false);
  const [clothData, setClothData] = useState({});
  const [openDeleteConfirmDialog, setOpenDeleteConfirmDialog] = useState(false);
  const [selectedBrand, setSelectedBrand] = useState({});
  const [searchBrand, setSearchBrand] = useState('');

  const [searchText, setSearchText] = useState('');
  const [clothPage, setClothPage] = useState(1);
  const total = cloths?.total;
  const totalCloths = Math.ceil(total / 12);
  // const identifier = 'course';
  // const storedRowsPerPage = localStorage.getItem(`rowsPerPage_${identifier}`);

  const handleCloseDeleteDialog = () => {
    setOpenDeleteConfirmDialog(false);
  };

  const handleDeleteClick = (cloth) => {
    setClothData(cloth);
    setOpenDeleteConfirmDialog(true);
  };

  const handleDeleteConfirmClick = () => {
    const credentials = {
      dispatch,
      clothId: clothData?._id,
      handleCloseDeleteDialog,
    };
    dispatch(deleteCloth(credentials));
  };

  const handleEditClick = (cloth) => {
    if (cloth) {
      setClothData(cloth);
    }
    setOpenClothCrudDialog(true);
  };

  const handleSearch = (value) => {
    setSearchText(value);
  };

  useEffect(() => {
    const credentials = {
      page: clothPage,
      search: searchText,
      dispatch,
      limit: 12,
      brand: selectedBrand?.value,
    };
    dispatch(getAllCloths(credentials));
  }, [dispatch, searchText, clothPage, selectedBrand?.value]);

  const brandOptions = [
    {
      label: 'All',
      value: '',
    },
    ...(brands?.brands || []).map((result) => ({
      label: result?.name,
      value: result?._id,
    })),
  ];

  const handleAutocompleteChangeBrand = (_, newValue) => {
    setSelectedBrand({
      value: newValue?.value || '',
      label: newValue?.label || '',
    });
  };

  const handleSearchBrand = (event) => {
    setSearchBrand(event.target.value);
  };

  useEffect(() => {
    const credentials = {
      page: 1,
      search: searchBrand,
      dispatch,
      limit: 10,
    };
    dispatch(getAllBrands(credentials));
  }, [dispatch, searchBrand]);

  return (
    <>
      {openClothCrudDialog && (
        <ClothCrud
          setOpen={setOpenClothCrudDialog}
          open={openClothCrudDialog}
          isUpdate={clothData}
          setClothData={setClothData}
        />
      )}

      <DeleteConfirmDialog
        onConfirmClick={handleDeleteConfirmClick}
        setOpen={setOpenDeleteConfirmDialog}
        open={openDeleteConfirmDialog}
        item={item}
        loading={loading}
        title={`Cloth "${clothData?.name}"`}
      />
      <Container maxWidth="xl">
        <CustomBreadcrumbs
          heading="Cloth"
          links={[
            {
              name: 'Dashboard',
              href: paths.dashboard.root,
            },

            {
              name: 'Cloth',
            },
          ]}
          action={
            <Stack flexDirection="row" spacing={2}>
              <Button
                onClick={() => setOpenClothCrudDialog(true)}
                variant="contained"
                color="inherit"
                startIcon={<Iconify icon="mingcute:add-line" />}
              >
                New Cloth
              </Button>
            </Stack>
          }
          sx={{
            mb: { xs: 3, md: 5 },
          }}
        />
        <Stack
          spacing={2}
          alignItems={{ sm: 'center' }}
          direction={{ xs: 'column', sm: 'row' }}
          mb={3}
        >
          <ClothSearch onSearch={handleSearch} isMobile={isMobile} />
          <Autocomplete
            id="country-select-demo"
            sx={{ width: isMobile ? '100%' : '30%' }}
            options={brandOptions}
            autoHighlight
            fullWidth
            getOptionLabel={(option) => option.label}
            onChange={handleAutocompleteChangeBrand}
            renderOption={(props, option) => (
              <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                {option.label}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Brand"
                onChange={handleSearchBrand}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'new-password', // disable autocomplete and autofill
                }}
              />
            )}
          />
          <Typography variant="subtitle1" textAlign="end" width="20%" pr={2}>
            Total: {cloths?.total || 0}
          </Typography>
        </Stack>
        {loading ? (
          <Paper
            sx={{
              textAlign: 'center',
            }}
          >
            <CircularProgress color="inherit" />
          </Paper>
        ) : (
          <>
            {cloths?.total > 0 ? (
              <ClothData
                clothData={cloths}
                handleEditClick={handleEditClick}
                handleDeleteClick={handleDeleteClick}
                page={clothPage}
                setPage={setClothPage}
                total={totalCloths}
              />
            ) : (
              <EmptyContent
                filled
                title="No Data"
                sx={{
                  py: 10,
                }}
              />
            )}
          </>
        )}
      </Container>
    </>
  );
}
