import { MetaHelmet } from 'src/layouts/Helmet/Helmet';

import Model from './ModelPage';

export default function AllModels() {
  return (
    <>
      <MetaHelmet title="Model" />
      <Model />
    </>
  );
}
