// import { lazy, Suspense } from 'react';
// import { Outlet, Navigate, useRoutes } from 'react-router-dom';

// import { useGetRoles } from 'src/hooks/useHandleSessions';

// import AuthGuard from 'src/auth/guard/auth-guard';
// import DashboardLayout from 'src/layouts/dashboard';
// import AccountView from 'src/pages/Common/Auth/profile';
// import DashboardAdminApp from 'src/pages/Common/Dashboard/DashboardAdminApp';

// import { LoadingScreen } from 'src/components/loading-screen';

// import { adminRoutes, publicRoutes } from './routes';

// const Page404 = lazy(() => import('src/pages/Common/Static/404'));

// // ----------------------------------------------------------------------

// export default function Router() {
//   const { role } = useGetRoles();

//   const handleDashboardApp = () => {
//     switch (role) {
//       case 'admin':
//         return <DashboardAdminApp />;
//       default:
//         return '';
//     }
//   };

//   const mainRouteContainer = [
//     {
//       path: '/dashboard',
//       element: (
//         <AuthGuard>
//           <DashboardLayout>
//             <Suspense fallback={<LoadingScreen />}>
//               <Outlet />
//             </Suspense>
//           </DashboardLayout>
//         </AuthGuard>
//       ),
//       children: [
//         { element: <Navigate to="/dashboard/app" />, index: true },
//         {
//           path: 'app',
//           element: handleDashboardApp(),
//         },
//         { path: 'profile', element: <AccountView /> },

//         // other routes are listed here with their roles
//         { path: '404', element: <Page404 /> },
//         { path: '*', element: <Navigate to="/dashboard/404" /> },
//       ],
//     },

//     // handle notfound routes
//     {
//       path: '/',
//       element: <Navigate to="/dashboard/app" replace />,
//     },
//     {
//       path: '*',
//       element: <Navigate to="/404" replace />,
//     },
//   ];

//   const handleRouteByRoles = () => {
//     switch (role) {
//       case 'admin': {
//         const modifiedRoute = mainRouteContainer?.map((route) =>
//           route.path === '/dashboard'
//             ? {
//                 ...route,
//                 children: [...route.children, ...adminRoutes],
//               }
//             : route
//         );
//         return modifiedRoute;
//       }

//       default:
//         return mainRouteContainer;
//     }
//   };

//   return useRoutes([
//     {
//       path: '/',
//       element: <Navigate to="/login" replace />,
//     },

//     ...handleRouteByRoles(),
//     ...publicRoutes,
//     // ...authRoutes,

//     // No match 404
//     { path: '*', element: <Navigate to="/404" replace /> },
//   ]);
// }


import { lazy, Suspense } from 'react';
import { Outlet, Navigate, useRoutes } from 'react-router-dom';

import { useGetRoles, useGetToken } from 'src/hooks/useHandleSessions';

import AuthGuard from 'src/auth/guard/auth-guard';
import DashboardLayout from 'src/layouts/dashboard';
import AccountView from 'src/pages/Common/Auth/profile';
import DashboardAdminApp from 'src/pages/Common/Dashboard/DashboardAdminApp';
// import DashboardStaffApp from 'src/pages/Common/Dashboard/DashboardStaffApp';

import { LoadingScreen } from 'src/components/loading-screen';

import { paths } from './paths';
import { adminRoutes, staffRoutes, publicRoutes } from './routes';

const Page404 = lazy(() => import('src/pages/Common/Static/404'));

// ----------------------------------------------------------------------

export default function Router() {
  const { role } = useGetRoles();
  const { token } = useGetToken('token');

  const handleDashboardApp = () => {
    switch (role) {
      case 'admin':
        return <DashboardAdminApp />;
      case 'staff': // Add case for staff
        return <DashboardAdminApp />;
      default:
        return '';
    }
  };

  const mainRouteContainer = [
    {
      path: '/dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout>
            <Suspense fallback={<LoadingScreen />}>
              <Outlet />
            </Suspense>
          </DashboardLayout>
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        {
          path: 'app',
          element: handleDashboardApp(),
        },
        { path: 'profile', element: <AccountView /> },

        // other routes are listed here with their roles
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/dashboard/404" /> },
      ],
    },

    // handle notfound routes
    {
      path: '/',
      element: <Navigate to="/dashboard/app" replace />,
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ];

  const handleRouteByRoles = () => {
    switch (role) {
      case 'admin': {
        const modifiedRoute = mainRouteContainer?.map((route) =>
          route.path === '/dashboard'
            ? {
                ...route,
                children: [...route.children, ...adminRoutes],
              }
            : route
        );
        return modifiedRoute;
      }

      case 'staff': { // Add case for staff
        const modifiedRoute = mainRouteContainer?.map((route) =>
          route.path === '/dashboard'
            ? {
                ...route,
                children: [...route.children, ...staffRoutes],
              }
            : route
        );
        return modifiedRoute;
      }

      default:
        return mainRouteContainer;
    }
  };

  return useRoutes([
    {
      path: '/',
      element: <Navigate to={token ? paths.dashboard.root : '/login'} replace />,
    },

    ...handleRouteByRoles(),
    ...publicRoutes,
    // ...authRoutes,

    // No match 404
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
