import { MetaHelmet } from 'src/layouts/Helmet/Helmet';

import ModelCategory from './ModelCategoryPage';

export default function AllModelCategories() {
  return (
    <>
      <MetaHelmet title="Model Category" />
      <ModelCategory />
    </>
  );
}
