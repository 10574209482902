import { MetaHelmet } from 'src/layouts/Helmet/Helmet';

import Cloth from './ClothPage';

export default function AllCloths() {
  return (
    <>
      <MetaHelmet title="Cloth" />
      <Cloth />
    </>
  );
}
