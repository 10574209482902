import { createAsyncThunk } from '@reduxjs/toolkit';

import { del, get, put, post } from './http';
import { activeSnack } from '../store/common';

export const createKanthoora = createAsyncThunk(
  'kanthoora/create',
  async ({ state, dispatch, handleClose, handleClear }) => {
    try {
      const URL = `/kanthoora/admin/new`;
      const response = await post(URL, state);
      if (response) {
        handleClose();
        handleClear();
        dispatch(activeSnack({ type: 'success', message: 'Kanthoora created successfully' }));
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getAllKanthooras = createAsyncThunk(
  'kanthoora/list',
  async ({ page, search, dispatch, limit }) => {
    try {
      const response = await get(
        `/kanthoora/admin/all?page=${page && page}&search=${search && search}&limit=${limit || 5}`
      );
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getKanthooraDetails = createAsyncThunk(
  'kanthoora/details',
  async ({ kanthooraId, dispatch }) => {
    try {
      const response = await get(`/kanthoora/admin/${kanthooraId || ''}`);
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const updateKanthooraDetails = createAsyncThunk(
  'kanthoora/update',
  async ({ state, kanthooraId, dispatch, handleClose }) => {
    try {
      const URL = `/kanthoora/admin/${kanthooraId}`;

      const response = await put(URL, state);
      if (response) {
        if (handleClose) {
          handleClose();
        }
        dispatch(activeSnack({ type: 'success', message: 'Kanthoora updated Successfully' }));
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const deleteKanthoora = createAsyncThunk(
  'kanthoora/delete',
  async ({ kanthooraId, dispatch, handleCloseDeleteDialog }) => {
    try {
      const response = await del(`/kanthoora/admin/${kanthooraId}/`);
      if (response) {
        handleCloseDeleteDialog();
        dispatch(activeSnack({ type: 'success', message: 'Kanthoora deleted Successfully' }));
        return response?._id;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
