import { createAsyncThunk } from '@reduxjs/toolkit';

import { paths } from 'src/routes/routes/paths';

import { del, get, put, post } from './http';
import { activeSnack } from '../store/common';

export const createBooking = createAsyncThunk(
  'booking/create',
  async ({ state, navigate, dispatch, handleClose, handleClear }) => {
    try {
      const URL = `/booking/admin/new`;
      const response = await post(URL, state);
      if (response) {
        if (navigate) {
          navigate(paths.dashboard.booking);
        }
        if (handleClose) {
          handleClose();
        }
        if (handleClear) {
          handleClear();
        }
        dispatch(activeSnack({ type: 'success', message: 'Booking created successfully' }));
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getAllBookings = createAsyncThunk(
  'booking/list',
  async ({ page, search, dispatch, limit, sort, date, customer }) => {
    try {
      const response = await get(
        `/booking/admin/all?page=${page && page}&search=${search && search}&limit=${
          limit || 5
        }&sort=${sort || ''}&customer=${customer || ''}&start_date=${
          date?.start_date || ''
        }&end_date=${date?.end_date || ''}`
      );
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getBookingDetails = createAsyncThunk(
  'booking/details',
  async ({ bookingId, dispatch }) => {
    try {
      const response = await get(`/booking/admin/${bookingId || ''}`);
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const updateBookingDetails = createAsyncThunk(
  'booking/update',
  async ({ state, bookingId, navigate, dispatch, handleClose, handleClear }) => {
    try {
      const URL = `/booking/admin/${bookingId}`;

      const response = await put(URL, state);
      if (response) {
        if (navigate) {
          navigate(paths.dashboard.booking_view(response?._id));
        }
        if (handleClose) {
          handleClose()
        }
        if (handleClear) {
          handleClear()
        }
        dispatch(activeSnack({ type: 'success', message: 'Booking updated Successfully' }));
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const deleteBooking = createAsyncThunk(
  'booking/delete',
  async ({ bookingId, dispatch, handleCloseDeleteDialog, navigate }) => {
    try {
      const response = await del(`/booking/admin/${bookingId}/`);
      if (response) {
        if (navigate) {
          navigate(paths.dashboard.booking);
        }
        if (handleCloseDeleteDialog) {
          handleCloseDeleteDialog();
        }
        dispatch(activeSnack({ type: 'success', message: 'Booking deleted Successfully' }));
        return response?._id;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
