import { createAsyncThunk } from '@reduxjs/toolkit';

import { get } from './http';
import { activeSnack } from '../store/common';

export const getBalanceSheet = createAsyncThunk(
  'balance-sheet/list',
  async ({ dispatch, dateValue }) => {
    try {
      const response = await get(
        `/balansheet/admin/data?start_date=${dateValue?.start_date || ''}&end_date=${
          dateValue?.end_date || ''
        }`
      );
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
