import { createSlice } from '@reduxjs/toolkit';

import { covertToJSON } from '../../utils/stateToJson';
import {
  createBank,
  deleteBank,
  getAllBanks,
  getBankDetails,
  getBankExcelData,
  updateBankDetails,
} from '../api/bank';

export const bankSlice = createSlice({
  name: 'bank',
  initialState: {
    loading: false,
    error: {},
    // staff
    banks: {},
    bankDetails: {},
    bankExcelData: {},
  },
  reducers: {
    clearStaffError: (state) => {
      state.error = true;
    },
    clearBankDetails: (state) => {
      state.bankDetails = {};
    },
  },
  extraReducers: {
    [createBank.pending]: (state) => {
      state.loading = true;
    },
    [createBank.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.banks;
      const newBankList = jsonState?.banks;

      const modifiedBankList = {
        ...jsonState,
        banks: Array.isArray(newBankList) ? [action.payload, ...newBankList] : [action.payload],
      };
      state.loading = false;
      state.banks = modifiedBankList;
      state.error = {};
    },
    [createBank.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [getAllBanks.pending]: (state) => {
      state.loading = true;
    },
    [getAllBanks.fulfilled]: (state, action) => {
      state.loading = false;
      state.banks = action.payload;
      state.error = {};
    },
    [getAllBanks.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [getBankDetails.pending]: (state) => {
      state.loading = true;
    },
    [getBankDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.bankDetails = action.payload;
      state.error = {};
    },
    [getBankDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [updateBankDetails.pending]: (state) => {
      state.loading = true;
    },
    [updateBankDetails.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.banks;
      const modifiedBankList = {
        ...jsonState,
        banks: jsonState.banks?.map((i) => (i._id === action.payload._id ? action?.payload : i)),
      };

      state.loading = false;
      state.banks = modifiedBankList;
      state.error = {};
    },
    [updateBankDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [deleteBank.pending]: (state) => {
      state.loading = true;
    },
    [deleteBank.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.banks;
      const modifiedBankList = {
        ...jsonState,
        banks: jsonState.banks?.filter((bank) => bank._id !== action.payload),
      };
      state.loading = false;
      state.banks = modifiedBankList;
      state.error = {};
    },
    [deleteBank.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [getBankExcelData.pending]: (state) => {
      state.loading = true;
    },
    [getBankExcelData.fulfilled]: (state, action) => {
      state.loading = false;
      state.bankExcelData = action.payload;
      state.error = {};
    },
    [getBankExcelData.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
  },
});
export const { clearStaffError, clearBankDetails } = bankSlice.actions;

export default bankSlice.reducer;
