import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// @mui
import {
  Box,
  Stack,
  Paper,
  Button,
  TextField,
  Container,
  Typography,
  Autocomplete,
  CircularProgress,
} from '@mui/material';

import { paths } from 'src/routes/routes/paths';

import { useResponsive } from 'src/hooks/use-responsive';

import { getAllModels } from 'src/server/api/model';
import { getAllKanthooras } from 'src/server/api/kanthoora';
import { deleteModelCategory, getAllModelCategories } from 'src/server/api/modelCategory';

// components
import Iconify from 'src/components/iconify';
import EmptyContent from 'src/components/empty-content/empty-content';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs/custom-breadcrumbs';

// sections
import DeleteConfirmDialog from 'src/sections/common/DeleteConfirm';
import ModelCategoryCrud from 'src/sections/ModelCategory/ModelCategoryCrud';
import ModelCategoryData from 'src/sections/ModelCategory/ModelCategoryData';
import ModelCategorySearch from 'src/sections/ModelCategory/ModelCategorySearch';

export default function ModelCategory() {
  const dispatch = useDispatch();
  const isMobile = useResponsive('down', 'sm');

  const { modelCategories, loading, models, kanthooras } = useSelector((state) => ({
    modelCategories: state.modelCategory.modelCategories,
    kanthooras: state.kanthoora.kanthooras,
    models: state.model.models,
    loading: state.modelCategory.loading,
  }));

  const item = {
    heading: 'Are you sure you want to delete this model category? This action cannot be undone.',
  };

  const [openModelCategoryCrudDialog, setOpenModelCategoryCrudDialog] = useState(false);
  const [modelCategoryData, setModelCategoryData] = useState({});
  const [openDeleteConfirmDialog, setOpenDeleteConfirmDialog] = useState(false);
  const [selectedModel, setSelectedModel] = useState({});
  const [searchModel, setSearchModel] = useState('');
  const [selectedKanthoora, setSelectedKanthoora] = useState({});
  const [searchKanthoora, setSearchKanthoora] = useState('');

  const [searchText, setSearchText] = useState('');
  const [modelCategoryPage, setModelCategoryPage] = useState(1);
  const total = modelCategories?.total;
  const totalModelCategorys = Math.ceil(total / 10);
  // const identifier = 'course';
  // const storedRowsPerPage = localStorage.getItem(`rowsPerPage_${identifier}`);

  const handleCloseDeleteDialog = () => {
    setOpenDeleteConfirmDialog(false);
  };

  const handleDeleteClick = (modelCategory) => {
    setModelCategoryData(modelCategory);
    setOpenDeleteConfirmDialog(true);
  };

  const handleDeleteConfirmClick = () => {
    const credentials = {
      dispatch,
      modelCategoryId: modelCategoryData?._id,
      handleCloseDeleteDialog,
    };
    dispatch(deleteModelCategory(credentials));
  };

  const handleEditClick = (modelCategory) => {
    if (modelCategory) {
      setModelCategoryData(modelCategory);
    }
    setOpenModelCategoryCrudDialog(true);
  };

  const handleSearch = (value) => {
    setSearchText(value);
  };

  useEffect(() => {
    const credentials = {
      page: modelCategoryPage,
      search: searchText,
      dispatch,
      limit: 10,
      model: selectedModel?.value,
      kanthoora: selectedKanthoora?.value,
    };
    dispatch(getAllModelCategories(credentials));
  }, [dispatch, searchText, modelCategoryPage, selectedModel?.value, selectedKanthoora?.value]);

  const modelOptions = [
    {
      label: 'All',
      value: '',
    },
    ...(models?.models || []).map((result) => ({
      label: result?.name,
      value: result?._id,
    })),
  ];

  const handleAutocompleteChangeModel = (_, newValue) => {
    setSelectedModel({
      value: newValue?.value || '',
      label: newValue?.label || '',
    });
  };

  const handleSearchModel = (event) => {
    setSearchModel(event.target.value);
  };

  useEffect(() => {
    const credentials = {
      page: 1,
      search: searchModel,
      dispatch,
      limit: 10,
    };
    dispatch(getAllModels(credentials));
  }, [dispatch, searchModel]);

  const customerOptions = [
    {
      label: 'All',
      value: '',
    },
    ...(kanthooras?.kanthooras || []).map((result) => ({
      label: result?.name,
      value: result?._id,
    })),
  ];

  const handleAutocompleteChangeKanthoora = (_, newValue) => {
    setSelectedKanthoora({
      value: newValue?.value || '',
      label: newValue?.label || '',
    });
  };

  const handleSearchKanthoora = (event) => {
    setSearchKanthoora(event.target.value);
  };

  useEffect(() => {
    const credentials = {
      page: 1,
      search: searchKanthoora,
      dispatch,
      limit: 10,
    };
    dispatch(getAllKanthooras(credentials));
  }, [dispatch, searchKanthoora]);

  return (
    <>
      {openModelCategoryCrudDialog && (
        <ModelCategoryCrud
          setOpen={setOpenModelCategoryCrudDialog}
          open={openModelCategoryCrudDialog}
          isUpdate={modelCategoryData}
          setModelCategoryData={setModelCategoryData}
        />
      )}

      <DeleteConfirmDialog
        onConfirmClick={handleDeleteConfirmClick}
        setOpen={setOpenDeleteConfirmDialog}
        open={openDeleteConfirmDialog}
        item={item}
        loading={loading}
        title={`ModelCategory "${modelCategoryData?.name}"`}
      />

      <Container maxWidth="xl">
        <CustomBreadcrumbs
          heading="Model Category"
          links={[
            {
              name: 'Dashboard',
              href: paths.dashboard.root,
            },

            {
              name: 'Model Category',
            },
          ]}
          action={
            <Stack flexDirection="row" spacing={2}>
              <Button
                onClick={() => setOpenModelCategoryCrudDialog(true)}
                variant="contained"
                color="inherit"
                startIcon={<Iconify icon="mingcute:add-line" />}
              >
                New Category
              </Button>
            </Stack>
          }
          sx={{
            mb: { xs: 3, md: 5 },
          }}
        />
        <Stack
          spacing={2}
          alignItems={{ sm: 'center' }}
          direction={{ xs: 'column', sm: 'row' }}
          mb={3}
        >
          <ModelCategorySearch onSearch={handleSearch} isMobile={isMobile} />
          <Autocomplete
            id="country-select-demo"
            sx={{ width: isMobile ? '100%' : '30%' }}
            options={modelOptions}
            autoHighlight
            fullWidth
            getOptionLabel={(option) => option.label}
            onChange={handleAutocompleteChangeModel}
            renderOption={(props, option) => (
              <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                {option.label}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Model"
                onChange={handleSearchModel}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'new-password', // disable autocomplete and autofill
                }}
              />
            )}
          />
          <Autocomplete
            id="country-select-demo"
            sx={{ width: isMobile ? '100%' : '30%' }}
            options={customerOptions}
            autoHighlight
            fullWidth
            getOptionLabel={(option) => option.label}
            onChange={handleAutocompleteChangeKanthoora}
            renderOption={(props, option) => (
              <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                {option.label}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Kanthoora"
                onChange={handleSearchKanthoora}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'new-password', // disable autocomplete and autofill
                }}
              />
            )}
          />
          <Typography variant="subtitle1" pr={5}>
            Total: {modelCategories?.total || 0}
          </Typography>
        </Stack>
        {loading ? (
          <Paper
            sx={{
              textAlign: 'center',
            }}
          >
            <CircularProgress color="inherit" />
          </Paper>
        ) : (
          <>
            {modelCategories?.total > 0 ? (
              <ModelCategoryData
                modelCategoryData={modelCategories}
                handleEditClick={handleEditClick}
                handleDeleteClick={handleDeleteClick}
                page={modelCategoryPage}
                setPage={setModelCategoryPage}
                total={totalModelCategorys}
              />
            ) : (
              <EmptyContent
                filled
                title="No Data"
                sx={{
                  py: 10,
                }}
              />
            )}
          </>
        )}
      </Container>
    </>
  );
}
