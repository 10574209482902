import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import { Stack, Tooltip, IconButton } from '@mui/material';

import { varAlpha } from 'src/theme/utils';
import { AvatarShape } from 'src/assets/illustrations';
import coverImg from 'src/assets/other/cloth-cover.webp';

import Image from 'src/components/image';
import Iconify from 'src/components/iconify';
import { useLightBox } from 'src/components/lightbox';
import Lightbox from 'src/components/lightbox/lightbox';
import TextMaxLine from 'src/components/text-max-line/text-max-line';

// ----------------------------------------------------------------------

export function ClothItem({ clothData, handleEditClick, handleDeleteClick }) {
  const slides = clothData?.images?.map((img) => ({
    src: img?.url,
  }));

  const lightbox = useLightBox(slides);

  return (
    <>
      <Lightbox
        index={lightbox.selected}
        slides={slides}
        open={lightbox.open}
        close={lightbox.onClose}
        onGetCurrentIndex={(index) => lightbox.setSelected(index)}
      />
      <Card>
        <Box sx={{ position: 'relative' }}>
          <AvatarShape
            sx={{
              left: 0,
              right: 0,
              zIndex: 10,
              mx: 'auto',
              bottom: -26,
              position: 'absolute',
            }}
          />

          {clothData?.brand?.image?.length > 0 && (
            <Avatar
              alt={clothData?.brand?.name}
              src={clothData?.brand?.image[0]?.url}
              sx={{
                width: 64,
                height: 64,
                zIndex: 11,
                left: 0,
                right: 0,
                bottom: -32,
                mx: 'auto',
                position: 'absolute',
              }}
            />
          )}

          {clothData?.images?.length > 0 ? (
            <Tooltip title="Click to view image">
              <Image
                src={clothData?.images[0]?.url}
                alt={clothData?.name}
                ratio="16/9"
                onClick={() => lightbox.onOpen(clothData?.images[0]?.url)}
                sx={{ cursor: 'pointer' }}
                slotProps={{
                  overlay: {
                    background: (theme) => varAlpha(theme.vars.palette.grey['900Channel'], 0.48),
                  },
                }}
              />
            </Tooltip>
          ) : (
            <Image
              src={coverImg}
              alt=""
              ratio="16/9"
              slotProps={{
                overlay: {
                  background: (theme) => varAlpha(theme.vars.palette.grey['900Channel'], 0.48),
                },
              }}
            />
          )}
        </Box>

        <ListItemText
          sx={{ mt: 7, mb: 1, textAlign: 'center' }}
          primary={clothData?.name}
          secondary={clothData?.brand?.name}
          primaryTypographyProps={{ typography: 'subtitle1' }}
          secondaryTypographyProps={{ component: 'span', mt: 0.5 }}
        />

        <Divider sx={{ borderStyle: 'dashed' }} />
        <Box px={1} pb={1} pt={0.5}>
          <TextMaxLine line={3} variant="caption" color="text.disabled">
            {clothData?.description}
          </TextMaxLine>
          <Stack direction="row" spacing={0} justifyContent="end" mt={2}>
            <IconButton color="info" onClick={() => handleEditClick(clothData)}>
              <Iconify icon="solar:pen-bold" />
            </IconButton>
            <IconButton color="error" onClick={() => handleDeleteClick(clothData)}>
              <Iconify icon="solar:trash-bin-trash-bold" />
            </IconButton>
          </Stack>
        </Box>
      </Card>
    </>
  );
}

ClothItem.propTypes = {
  clothData: PropTypes.object,
  handleEditClick: PropTypes.any,
  handleDeleteClick: PropTypes.any,
};
