import { createSlice } from '@reduxjs/toolkit';

import { covertToJSON } from '../../utils/stateToJson';
import {
  createIncome,
  deleteIncome,
  getAllIncomes,
  getIncomeDetails,
  getIncomeExcelData,
  updateIncomeDetails,
} from '../api/income';

export const incomeSlice = createSlice({
  name: 'income',
  initialState: {
    loading: false,
    error: {},
    // staff
    incomes: {},
    incomeDetails: {},
    incomeExcelData: {},
  },
  reducers: {
    clearStaffError: (state) => {
      state.error = true;
    },
    clearIncomeDetails: (state) => {
      state.incomeDetails = {};
    },
  },
  extraReducers: {
    [createIncome.pending]: (state) => {
      state.loading = true;
    },
    [createIncome.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.incomes;
      const newIncomeList = jsonState?.incomes;

      const modifiedIncomeList = {
        ...jsonState,
        incomes: Array.isArray(newIncomeList)
          ? [action.payload, ...newIncomeList]
          : [action.payload],
      };
      state.loading = false;
      state.incomes = modifiedIncomeList;
      state.error = {};
    },
    [createIncome.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [getAllIncomes.pending]: (state) => {
      state.loading = true;
    },
    [getAllIncomes.fulfilled]: (state, action) => {
      state.loading = false;
      state.incomes = action.payload;
      state.error = {};
    },
    [getAllIncomes.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [getIncomeDetails.pending]: (state) => {
      state.loading = true;
    },
    [getIncomeDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.incomeDetails = action.payload;
      state.error = {};
    },
    [getIncomeDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [updateIncomeDetails.pending]: (state) => {
      state.loading = true;
    },
    [updateIncomeDetails.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.incomes;
      const modifiedIncomeList = {
        ...jsonState,
        incomes: jsonState.incomes?.map((i) =>
          i._id === action.payload._id ? action?.payload : i
        ),
      };

      state.loading = false;
      state.incomes = modifiedIncomeList;
      state.error = {};
    },
    [updateIncomeDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [deleteIncome.pending]: (state) => {
      state.loading = true;
    },
    [deleteIncome.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.incomes;
      const modifiedIncomeList = {
        ...jsonState,
        incomes: jsonState.incomes?.filter((income) => income._id !== action.payload),
      };
      state.loading = false;
      state.incomes = modifiedIncomeList;
      state.error = {};
    },
    [deleteIncome.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [getIncomeExcelData.pending]: (state) => {
      state.loading = true;
    },
    [getIncomeExcelData.fulfilled]: (state, action) => {
      state.loading = false;
      state.incomeExcelData = action.payload;
      state.error = {};
    },
    [getIncomeExcelData.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
  },
});
export const { clearStaffError, clearIncomeDetails } = incomeSlice.actions;

export default incomeSlice.reducer;
