import { createSlice } from '@reduxjs/toolkit';

import { getBalanceSheet } from '../api/balanceSheet';

export const balanceSheetSlice = createSlice({
  name: 'balanceSheet',
  initialState: {
    loading: false,
    error: {},
    balanceSheetData: {},
  },
  extraReducers: {
    [getBalanceSheet.pending]: (state) => {
      state.loading = true;
    },
    [getBalanceSheet.fulfilled]: (state, action) => {
      state.loading = false;
      state.balanceSheetData = action.payload;
      state.error = {};
    },
    [getBalanceSheet.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
  },
});

export default balanceSheetSlice.reducer;
