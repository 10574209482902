import { createAsyncThunk } from '@reduxjs/toolkit';

import { paths } from 'src/routes/routes/paths';

import { del, get, put, post } from './http';
import { activeSnack } from '../store/common';

export const createVehicle = createAsyncThunk(
  'vehicle/create',
  async ({ state, navigate, dispatch }) => {
    try {
      const URL = `/vehicle/admin/new`;
      const response = await post(URL, state);
      if (response) {
        if (navigate) {
          navigate(paths.dashboard.vehicle);
        }
        dispatch(activeSnack({ type: 'success', message: 'Vehicle created successfully' }));
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getAllVehicles = createAsyncThunk(
  'vehicle/list',
  async ({ page, search, dispatch, limit, brand, fuel, transmission, sort }) => {
    try {
      const response = await get(
        `/vehicle/admin/all?page=${page && page}&search=${search && search}&limit=${
          limit || 5
        }&brand=${brand || ''}&fuel_type=${fuel || ''}&transmission_type=${
          transmission || ''
        }&sort=${sort || ''}`
      );
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getVehicleDetails = createAsyncThunk(
  'vehicle/details',
  async ({ vehicleId, dispatch }) => {
    try {
      const response = await get(`/vehicle/admin/${vehicleId || ''}`);
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const updateVehicleDetails = createAsyncThunk(
  'vehicle/update',
  async ({ state, vehicleId, navigate, dispatch }) => {
    try {
      const URL = `/vehicle/admin/${vehicleId}`;

      const response = await put(URL, state);
      if (response) {
        if (navigate) {
          navigate(paths.dashboard.vehicle_view(response?._id));
        }
        dispatch(
          activeSnack({
            type: 'success',
            message: navigate ? 'Vehicle updated Successfully' : 'Vehicle status updated',
          })
        );
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const deleteVehicle = createAsyncThunk(
  'vehicle/delete',
  async ({ vehicleId, dispatch, handleCloseDeleteDialog, navigate }) => {
    try {
      const response = await del(`/vehicle/admin/${vehicleId}/`);
      if (response) {
        if (navigate) {
          navigate(paths.dashboard.vehicle);
        }
        handleCloseDeleteDialog();
        dispatch(activeSnack({ type: 'success', message: 'Vehicle deleted Successfully' }));
        return response?._id;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
