import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useMemo, useState, useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';

import { LoadingButton } from '@mui/lab';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
// @mui
import {
  Box,
  Stack,
  Alert,
  Dialog,
  TextField,
  DialogTitle,
  Autocomplete,
  DialogContent,
  DialogActions,
} from '@mui/material';

import { getAllKanthooras } from 'src/server/api/kanthoora';
import { createModel, updateModelDetails } from 'src/server/api/model';

// components
import { RHFTextField } from 'src/components/hook-form';
import FormProvider from 'src/components/hook-form/form-provider';
import { FileUploadCustom } from 'src/components/CustomFields/ImageUpload';

ModelCrud.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  setModelData: PropTypes.func,
  isUpdate: PropTypes.object,
};

export default function ModelCrud({ open, setOpen, isUpdate, setModelData }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleClose = () => {
    setOpen(false);
    setModelData({});
  };
  const dispatch = useDispatch();

  const { loading, error, kanthooras } = useSelector((state) => ({
    kanthooras: state.kanthoora.kanthooras,
    loading: state.model.loading,
    error: state.model.error,
  }));

  const [modelImage, setModelImage] = useState([]);
  const [selectedKanthoora, setSelectedKanthoora] = useState({
    label: '',
    value: '',
  });
  const [searchTextKanthoora, setSearchTextKanthoora] = useState('');

  const ModelSchema = Yup.object().shape({
    name: Yup.string().required(),
    description: Yup.string(),
  });

  const defaultKanthoora = useMemo(
    () => ({
      label: isUpdate?.kanthoora?.name || '',
      value: isUpdate?.kanthoora?._id || '',
    }),
    [isUpdate]
  );

  const defaultValues = useMemo(
    () => ({
      name: isUpdate?.name || '',
      kanthoora: defaultKanthoora || {},
      description: isUpdate?.description || '',
      images: modelImage || [],
    }),
    [modelImage, isUpdate?.description, isUpdate?.name, defaultKanthoora]
  );

  useEffect(() => {
    if (isUpdate?._id) {
      setModelImage(isUpdate?.images || []);
      setSelectedKanthoora({
        label: isUpdate?.kanthoora?.name,
        value: isUpdate?.kanthoora?._id,
      });
    }
  }, [isUpdate]);

  const methods = useForm({
    resolver: yupResolver(ModelSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
    reset,
  } = methods;

  const handleClear = () => {
    reset(defaultValues);
  };

  useEffect(() => {
    const credentials = {
      page: 1,
      search: searchTextKanthoora,
      dispatch,
      limit: 10,
    };
    dispatch(getAllKanthooras(credentials));
  }, [dispatch, searchTextKanthoora]);

  const kanthooraOptions = [
    ...(kanthooras?.kanthooras || []).map((result) => ({
      label: result?.name,
      value: result?._id,
    })),
  ];

  const handleAutocompleteChangeKanthoora = (_, newValue) => {
    setSelectedKanthoora({
      value: newValue?.value || '',
      label: newValue?.label || '',
    });
  };

  const handleSearchKanthoora = (event) => {
    setSearchTextKanthoora(event.target.value);
  };

  const renderForm = (
    <>
      <Stack sx={{ width: '100%', marginBottom: '15px' }} spacing={2}>
        {error.message && <Alert severity="error">{error?.message}</Alert>}
      </Stack>
      <Stack spacing={2} direction="row" mb="24px">
        <RHFTextField name="name" label="Name" type="text" required />
      </Stack>
      <Stack spacing={2} direction="row" mb="24px">
        <Autocomplete
          id="partner-select-demo"
          sx={{ width: '100%' }}
          options={kanthooraOptions}
          autoHighlight
          getOptionLabel={(option) => option.label}
          isOptionEqualToValue={(option, value) => option.value === value.value}
          renderOption={(props, option) => (
            <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
              {option?.label}
            </Box>
          )}
          value={selectedKanthoora}
          onChange={handleAutocompleteChangeKanthoora}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Kanthoora"
              required
              // name="head_partner_type"
              onChange={handleSearchKanthoora}
              inputProps={{
                ...params.inputProps,
              }}
              size="medium"
            />
          )}
        />
      </Stack>
      <Stack spacing={2} direction="row" mb="24px">
        <RHFTextField name="description" type="text" label="Description" multiline rows={4} />
      </Stack>
      <Stack spacing={2} direction="row" mb="24px">
        <FileUploadCustom
          setImagesPreview={setModelImage}
          imagesPreview={modelImage}
          title="Upload Image"
          imageSize={4}
          // isMultiple
          // uploadUrl="stemxpert"
          // onChangeUpdate={updateProductWhenImageChange}
          // isUpdate={isUpdateData}
        />
      </Stack>
    </>
  );

  const onSubmit = handleSubmit(async (data) => {
    const credentialsCreate = {
      state: {
        ...data,
        kanthoora: selectedKanthoora?.value,
        images: modelImage,
      },
      dispatch,
      handleClose,
      handleClear,
    };

    if (isUpdate?._id) {
      const credentialsUpdate = {
        state: {
          ...data,
          kanthoora: selectedKanthoora?.value,
          images: modelImage,
        },
        modelId: isUpdate?._id,
        dispatch,
        handleClose,
        handleClear,
      };
      dispatch(updateModelDetails(credentialsUpdate));
    } else {
      dispatch(createModel(credentialsCreate));
    }
  });

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {isUpdate?._id ? 'Update Model' : 'New Model'}
        </DialogTitle>
        <DialogContent sx={{ minWidth: '450px' }}>
          <FormProvider methods={methods} onSubmit={onSubmit}>
            {renderForm}
            <Stack direction="column">
              <DialogActions
                sx={{
                  mt: 2,
                  p: 0,
                  py: 5,
                }}
              >
                <Stack direction="row" spacing={1}>
                  <LoadingButton
                    sx={{
                      padding: '5px 20px',
                    }}
                    size="medium"
                    type="submit"
                    variant="contained"
                    color="inherit"
                    loading={isSubmitting || loading}
                    autoFocus
                  >
                    {isUpdate?._id ? 'Update' : 'Create'}
                  </LoadingButton>
                  <Button size="medium" onClick={handleClose}>
                    Cancel
                  </Button>
                </Stack>
              </DialogActions>
            </Stack>
          </FormProvider>
        </DialogContent>
      </Dialog>
    </div>
  );
}
