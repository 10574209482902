import { createAsyncThunk } from '@reduxjs/toolkit';

import { del, get, put, post } from './http';
import { activeSnack } from '../store/common';

export const createShop = createAsyncThunk(
  'shop/create',
  async ({ state, dispatch, handleClose, handleClear }) => {
    try {
      const URL = `/shop/admin/new`;
      const response = await post(URL, state);
      if (response) {
        handleClose();
        handleClear();
        dispatch(activeSnack({ type: 'success', message: 'Shop created successfully' }));
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getAllShops = createAsyncThunk(
  'shop/list',
  async ({ page, search, dispatch, limit }) => {
    try {
      const response = await get(
        `/shop/admin/all?page=${page && page}&search=${search && search}&limit=${limit || 5}`
      );
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getShopDetails = createAsyncThunk('shop/details', async ({ shopId, dispatch }) => {
  try {
    const response = await get(`/shop/admin/${shopId || ''}`);
    if (response) {
      return response;
    }
    return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
  } catch (error) {
    dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
    throw error?.response?.data?.message;
  }
});

export const updateShopDetails = createAsyncThunk(
  'shop/update',
  async ({ state, shopId, dispatch, handleClose }) => {
    try {
      const URL = `/shop/admin/${shopId}`;

      const response = await put(URL, state);
      if (response) {
        if (handleClose) {
          handleClose();
        }
        dispatch(activeSnack({ type: 'success', message: 'Shop updated Successfully' }));
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const deleteShop = createAsyncThunk(
  'shop/delete',
  async ({ shopId, dispatch, handleCloseDeleteDialog }) => {
    try {
      const response = await del(`/shop/admin/${shopId}/`);
      if (response) {
        handleCloseDeleteDialog();
        dispatch(activeSnack({ type: 'success', message: 'Shop deleted Successfully' }));
        return response?._id;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
