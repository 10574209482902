import { createSlice } from '@reduxjs/toolkit';

import { covertToJSON } from '../../utils/stateToJson';
import {
  createExpense,
  deleteExpense,
  getAllExpenses,
  getExpenseDetails,
  getExpenseExcelData,
  updateExpenseDetails,
} from '../api/expense';

export const expenseSlice = createSlice({
  name: 'expense',
  initialState: {
    loading: false,
    error: {},
    // staff
    expenses: {},
    expenseDetails: {},
    expenseExcelData: {},
  },
  reducers: {
    clearStaffError: (state) => {
      state.error = true;
    },
    clearExpenseDetails: (state) => {
      state.expenseDetails = {};
    },
  },
  extraReducers: {
    [createExpense.pending]: (state) => {
      state.loading = true;
    },
    [createExpense.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.expenses;
      const newExpenseList = jsonState?.expenses;

      const modifiedExpenseList = {
        ...jsonState,
        expenses: Array.isArray(newExpenseList)
          ? [action.payload, ...newExpenseList]
          : [action.payload],
      };
      state.loading = false;
      state.expenses = modifiedExpenseList;
      state.error = {};
    },
    [createExpense.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [getAllExpenses.pending]: (state) => {
      state.loading = true;
    },
    [getAllExpenses.fulfilled]: (state, action) => {
      state.loading = false;
      state.expenses = action.payload;
      state.error = {};
    },
    [getAllExpenses.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [getExpenseDetails.pending]: (state) => {
      state.loading = true;
    },
    [getExpenseDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.expenseDetails = action.payload;
      state.error = {};
    },
    [getExpenseDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [updateExpenseDetails.pending]: (state) => {
      state.loading = true;
    },
    [updateExpenseDetails.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.expenses;
      const modifiedExpenseList = {
        ...jsonState,
        expenses: jsonState.expenses?.map((i) =>
          i._id === action.payload._id ? action?.payload : i
        ),
      };

      state.loading = false;
      state.expenses = modifiedExpenseList;
      state.error = {};
    },
    [updateExpenseDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [deleteExpense.pending]: (state) => {
      state.loading = true;
    },
    [deleteExpense.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.expenses;
      const modifiedExpenseList = {
        ...jsonState,
        expenses: jsonState.expenses?.filter((expense) => expense._id !== action.payload),
      };
      state.loading = false;
      state.expenses = modifiedExpenseList;
      state.error = {};
    },
    [deleteExpense.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [getExpenseExcelData.pending]: (state) => {
      state.loading = true;
    },
    [getExpenseExcelData.fulfilled]: (state, action) => {
      state.loading = false;
      state.expenseExcelData = action.payload;
      state.error = {};
    },
    [getExpenseExcelData.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
  },
});
export const { clearStaffError, clearExpenseDetails } = expenseSlice.actions;

export default expenseSlice.reducer;
