import PropTypes from 'prop-types';

// @mui
import { Box, Stack } from '@mui/material';
import Pagination from '@mui/material/Pagination';

import { ClothItem } from './ClothItem';

ClothData.propTypes = {
  clothData: PropTypes.object,
  page: PropTypes.any,
  setPage: PropTypes.any,
  total: PropTypes.any,
  handleEditClick: PropTypes.any,
  handleDeleteClick: PropTypes.any,
};

export default function ClothData({
  clothData,
  page,
  setPage,
  total,
  handleEditClick,
  handleDeleteClick,
}) {
  const handleChange = (event, value) => {
    setPage(value);
  };
  return (
    <>
      <Box
        gap={3}
        display="grid"
        gridTemplateColumns={{
          xs: 'repeat(1, 1fr)',
          sm: 'repeat(2, 1fr)',
          md: 'repeat(4, 1fr)',
        }}
      >
        {clothData?.cloths?.map((cloth, key) => (
          <ClothItem
            key={key}
            clothData={cloth}
            handleEditClick={handleEditClick}
            handleDeleteClick={handleDeleteClick}
          />
        ))}
      </Box>
      {clothData?.total > 12 && (
        <Stack direction="row" justifyContent="center" mt={8}>
          <Pagination
            count={total}
            page={page}
            onChange={handleChange}
            className="custom-pagination"
          />
        </Stack>
      )}
    </>
  );
}
