import { createAsyncThunk } from '@reduxjs/toolkit';

import { paths } from 'src/routes/routes/paths';

import { del, get, put, post } from './http';
import { activeSnack } from '../store/common';

export const createRental = createAsyncThunk(
  'rental/create',
  async ({ state, navigate, dispatch }) => {
    try {
      const URL = `/rental/admin/new`;
      const response = await post(URL, state);
      if (response) {
        if (navigate) {
          navigate(paths.dashboard.rental);
        }
        dispatch(activeSnack({ type: 'success', message: 'Rental created successfully' }));
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getAllRentals = createAsyncThunk(
  'rental/list',
  async ({ page, search, dispatch, limit, sort, date, customer, vehicle }) => {
    try {
      const response = await get(
        `/rental/admin/all?page=${page || 1}&search=${search || ''}&limit=${limit || 5}&sort=${
          sort || ''
        }&start_date=${date?.start_date || ''}&end_date=${date?.end_date || ''}&customer=${
          customer || ''
        }&vehicle=${vehicle || ''}`
      );
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getRentalDetails = createAsyncThunk(
  'rental/details',
  async ({ rentalId, dispatch }) => {
    try {
      const response = await get(`/rental/admin/${rentalId || ''}`);
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const updateRentalDetails = createAsyncThunk(
  'rental/update',
  async ({ state, rentalId, navigate, dispatch, handleClose, handleClear }) => {
    try {
      const URL = `/rental/admin/${rentalId}`;

      const response = await put(URL, state);
      if (response) {
        if (navigate) {
          navigate(paths.dashboard.rental_view(response?._id));
        }
        if (handleClose) {
          handleClose();
        }
        if (handleClear) {
          handleClear();
        }
        dispatch(getRentalDetails({ rentalId: response?._id, dispatch }));
        dispatch(getCustomerBalance({ rentalId: response?._id, dispatch }));
        dispatch(activeSnack({ type: 'success', message: 'Rental updated Successfully' }));
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const deleteRental = createAsyncThunk(
  'rental/delete',
  async ({ rentalId, dispatch, handleCloseDeleteDialog, navigate }) => {
    try {
      const response = await del(`/rental/admin/${rentalId}/`);
      if (response) {
        if (navigate) {
          navigate(paths.dashboard.rental);
        }
        if (handleCloseDeleteDialog) {
          handleCloseDeleteDialog();
        }
        const cred = {
          limit: localStorage.getItem(`rowsPerPage_rental`),
          dispatch,
        };
        dispatch(getAllRentals(cred));
        const cred2 = {
          rentalId: response?._id,
          dispatch,
        };
        dispatch(getCustomerBalance(cred2));
        dispatch(activeSnack({ type: 'success', message: 'Rental deleted Successfully' }));
        return response?._id;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getCustomerBalance = createAsyncThunk(
  'rental/customer-balance',
  async ({ rentalId, dispatch }) => {
    try {
      const response = await get(`/rental/admin/customer-balance/${rentalId || ''}`);
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getRentalExcelData = createAsyncThunk(
  'rental/download',
  async ({ search, dispatch, sort, date, customer, vehicle, setOpenExcelDialog }) => {
    try {
      const response = await get(
        `/rental/admin/excel/print?search=${search || ''}&sort=${sort || ''}&start_date=${
          date?.start_date || ''
        }&end_date=${date?.end_date || ''}&customer=${customer || ''}&vehicle=${vehicle || ''}`
      );
      if (response) {
        setOpenExcelDialog(true);
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
