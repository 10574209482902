import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useMemo, useState, useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';

import { LoadingButton } from '@mui/lab';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
// @mui
import {
  Box,
  Stack,
  Alert,
  Dialog,
  TextField,
  DialogTitle,
  Autocomplete,
  DialogContent,
  DialogActions,
} from '@mui/material';

import { getAllBrands } from 'src/server/api/brand';
import { createCloth, updateClothDetails } from 'src/server/api/cloth';

// components
import { RHFTextField } from 'src/components/hook-form';
import FormProvider from 'src/components/hook-form/form-provider';
import { FileUploadCustom } from 'src/components/CustomFields/ImageUpload';

ClothCrud.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  setClothData: PropTypes.func,
  isUpdate: PropTypes.object,
};

export default function ClothCrud({ open, setOpen, isUpdate, setClothData }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleClose = () => {
    setOpen(false);
    setClothData({});
  };
  const dispatch = useDispatch();

  const { loading, error, brands } = useSelector((state) => ({
    brands: state.brand.brands,
    loading: state.cloth.loading,
    error: state.cloth.error,
  }));

  const [clothImage, setClothImage] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState({
    label: '',
    value: '',
  });
  const [searchTextBrand, setSearchTextBrand] = useState('');

  const ClothSchema = Yup.object().shape({
    name: Yup.string().required(),
    description: Yup.string(),
  });

  const defaultBrand = useMemo(
    () => ({
      label: isUpdate?.brand?.name || '',
      value: isUpdate?.brand?._id || '',
    }),
    [isUpdate]
  );

  const defaultValues = useMemo(
    () => ({
      name: isUpdate?.name || '',
      brand: defaultBrand || {},
      description: isUpdate?.description || '',
      images: clothImage || [],
    }),
    [clothImage, isUpdate?.description, isUpdate?.name, defaultBrand]
  );

  useEffect(() => {
    if (isUpdate?._id) {
      setClothImage(isUpdate?.images || []);
      setSelectedBrand({
        label: isUpdate?.brand?.name,
        value: isUpdate?.brand?._id,
      });
    }
  }, [isUpdate]);

  const methods = useForm({
    resolver: yupResolver(ClothSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
    reset,
  } = methods;

  const handleClear = () => {
    reset(defaultValues);
  };

  useEffect(() => {
    const credentials = {
      page: 1,
      search: searchTextBrand,
      dispatch,
      limit: 10,
    };
    dispatch(getAllBrands(credentials));
  }, [dispatch, searchTextBrand]);

  const brandOptions = [
    ...(brands?.brands || []).map((result) => ({
      label: result?.name,
      value: result?._id,
    })),
  ];

  const handleAutocompleteChangeBrand = (_, newValue) => {
    setSelectedBrand({
      value: newValue?.value || '',
      label: newValue?.label || '',
    });
  };

  const handleSearchBrand = (event) => {
    setSearchTextBrand(event.target.value);
  };

  const renderForm = (
    <>
      <Stack sx={{ width: '100%', marginBottom: '15px' }} spacing={2}>
        {error.message && <Alert severity="error">{error?.message}</Alert>}
      </Stack>
      <Stack spacing={2} direction="row" mb="24px">
        <RHFTextField name="name" label="Name" type="text" required />
      </Stack>
      <Stack spacing={2} direction="row" mb="24px">
        <Autocomplete
          id="partner-select-demo"
          sx={{ width: '100%' }}
          options={brandOptions}
          autoHighlight
          getOptionLabel={(option) => option.label}
          isOptionEqualToValue={(option, value) => option.value === value.value}
          renderOption={(props, option) => (
            <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
              {option?.label}
            </Box>
          )}
          value={selectedBrand}
          onChange={handleAutocompleteChangeBrand}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Brand"
              required
              // name="head_partner_type"
              onChange={handleSearchBrand}
              inputProps={{
                ...params.inputProps,
              }}
              size="medium"
            />
          )}
        />
      </Stack>
      <Stack spacing={2} direction="row" mb="24px">
        <RHFTextField name="description" type="text" label="Description" multiline rows={4} />
      </Stack>
      <Stack spacing={2} direction="row" mb="24px">
        <FileUploadCustom
          setImagesPreview={setClothImage}
          imagesPreview={clothImage}
          title="Upload Image"
          imageSize={4}
          isMultiple
          // uploadUrl="stemxpert"
          // onChangeUpdate={updateProductWhenImageChange}
          // isUpdate={isUpdateData}
        />
      </Stack>
    </>
  );

  const onSubmit = handleSubmit(async (data) => {
    const credentialsCreate = {
      state: {
        ...data,
        brand: selectedBrand?.value,
        images: clothImage,
      },
      dispatch,
      handleClose,
      handleClear,
    };

    if (isUpdate?._id) {
      const credentialsUpdate = {
        state: {
          ...data,
          brand: selectedBrand?.value,
          images: clothImage,
        },
        clothId: isUpdate?._id,
        dispatch,
        handleClose,
        handleClear,
      };
      dispatch(updateClothDetails(credentialsUpdate));
    } else {
      dispatch(createCloth(credentialsCreate));
    }
  });

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {isUpdate?._id ? 'Update Cloth' : 'New Cloth'}
        </DialogTitle>
        <DialogContent sx={{ minWidth: '450px' }}>
          <FormProvider methods={methods} onSubmit={onSubmit}>
            {renderForm}
            <Stack direction="column">
              <DialogActions
                sx={{
                  mt: 2,
                  p: 0,
                  py: 5,
                }}
              >
                <Stack direction="row" spacing={1}>
                  <LoadingButton
                    sx={{
                      padding: '5px 20px',
                    }}
                    size="medium"
                    type="submit"
                    variant="contained"
                    color="inherit"
                    loading={isSubmitting || loading}
                    autoFocus
                  >
                    {isUpdate?._id ? 'Update' : 'Create'}
                  </LoadingButton>
                  <Button size="medium" onClick={handleClose}>
                    Cancel
                  </Button>
                </Stack>
              </DialogActions>
            </Stack>
          </FormProvider>
        </DialogContent>
      </Dialog>
    </div>
  );
}
