import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// @mui
import { Stack, Paper, Button, Container, Typography, CircularProgress } from '@mui/material';

import { paths } from 'src/routes/routes/paths';

import { useResponsive } from 'src/hooks/use-responsive';

import { deleteKanthoora, getAllKanthooras } from 'src/server/api/kanthoora';

// components
import Iconify from 'src/components/iconify';
import EmptyContent from 'src/components/empty-content/empty-content';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs/custom-breadcrumbs';

import KanthooraCrud from 'src/sections/Kanthoora/KanthooraCrud';
import KanthooraData from 'src/sections/Kanthoora/KanthooraData';
// sections
import DeleteConfirmDialog from 'src/sections/common/DeleteConfirm';
import KanthooraSearch from 'src/sections/Kanthoora/KanthooraSearch';

export default function Kanthoora() {
  const dispatch = useDispatch();
  const isMobile = useResponsive('down', 'sm');

  const { kanthooras, loading } = useSelector((state) => ({
    kanthooras: state.kanthoora.kanthooras,
    loading: state.kanthoora.loading,
  }));

  const item = {
    heading: 'Are you sure you want to delete this kanthoora? This action cannot be undone.',
  };

  const [openKanthooraCrudDialog, setOpenKanthooraCrudDialog] = useState(false);
  const [kanthooraData, setKanthooraData] = useState({});
  const [openDeleteConfirmDialog, setOpenDeleteConfirmDialog] = useState(false);

  const [searchText, setSearchText] = useState('');
  const [kanthooraPage, setKanthooraPage] = useState(1);
  const total = kanthooras?.total;
  const totalKanthooras = Math.ceil(total / 12);
  // const identifier = 'course';
  // const storedRowsPerPage = localStorage.getItem(`rowsPerPage_${identifier}`);

  const handleCloseDeleteDialog = () => {
    setOpenDeleteConfirmDialog(false);
  };

  const handleDeleteClick = (kanthoora) => {
    setKanthooraData(kanthoora);
    setOpenDeleteConfirmDialog(true);
  };

  const handleDeleteConfirmClick = () => {
    const credentials = {
      dispatch,
      kanthooraId: kanthooraData?._id,
      handleCloseDeleteDialog,
    };
    dispatch(deleteKanthoora(credentials));
  };

  const handleEditClick = (kanthoora) => {
    if (kanthoora) {
      setKanthooraData(kanthoora);
    }
    setOpenKanthooraCrudDialog(true);
  };

  const handleSearch = (value) => {
    setSearchText(value);
  };

  useEffect(() => {
    const credentials = {
      page: kanthooraPage,
      search: searchText,
      dispatch,
      limit: 12,
    };
    dispatch(getAllKanthooras(credentials));
  }, [dispatch, searchText, kanthooraPage]);

  return (
    <>
      {openKanthooraCrudDialog && (
        <KanthooraCrud
          setOpen={setOpenKanthooraCrudDialog}
          open={openKanthooraCrudDialog}
          isUpdate={kanthooraData}
          setKanthooraData={setKanthooraData}
        />
      )}

      <DeleteConfirmDialog
        onConfirmClick={handleDeleteConfirmClick}
        setOpen={setOpenDeleteConfirmDialog}
        open={openDeleteConfirmDialog}
        item={item}
        loading={loading}
        title={`Kanthoora "${kanthooraData?.name}"`}
      />

      <Container maxWidth="xl">
        <CustomBreadcrumbs
          heading="Kanthoora"
          links={[
            {
              name: 'Dashboard',
              href: paths.dashboard.root,
            },

            {
              name: 'Kanthoora',
            },
          ]}
          action={
            <Stack flexDirection="row" spacing={2}>
              <Button
                onClick={() => setOpenKanthooraCrudDialog(true)}
                variant="contained"
                color="inherit"
                startIcon={<Iconify icon="mingcute:add-line" />}
              >
                New Kanthoora
              </Button>
            </Stack>
          }
          sx={{
            mb: { xs: 3, md: 5 },
          }}
        />
        <Stack
          spacing={3}
          justifyContent="space-between"
          alignItems={{ sm: 'center' }}
          direction={{ xs: 'column', sm: 'row' }}
          mb={3}
        >
          <KanthooraSearch onSearch={handleSearch} isMobile={isMobile} />
          <Typography variant="subtitle1" pr={5}>
            Total: {kanthooras?.total || 0}
          </Typography>
        </Stack>
        {loading ? (
          <Paper
            sx={{
              textAlign: 'center',
            }}
          >
            <CircularProgress color="inherit" />
          </Paper>
        ) : (
          <>
            {kanthooras?.total > 0 ? (
              <KanthooraData
                kanthooraData={kanthooras}
                handleEditClick={handleEditClick}
                handleDeleteClick={handleDeleteClick}
                page={kanthooraPage}
                setPage={setKanthooraPage}
                total={totalKanthooras}
              />
            ) : (
              <EmptyContent
                filled
                title="No Data"
                sx={{
                  py: 10,
                }}
              />
            )}
          </>
        )}
      </Container>
    </>
  );
}
