import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// @mui
import { Stack, Paper, Button, Container, Typography, CircularProgress } from '@mui/material';

import { paths } from 'src/routes/routes/paths';

import { useResponsive } from 'src/hooks/use-responsive';

import { deleteShop, getAllShops } from 'src/server/api/shop';

// components
import Iconify from 'src/components/iconify';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs/custom-breadcrumbs';

import ShopCrud from 'src/sections/Shop/ShopCrud';
import ShopData from 'src/sections/Shop/ShopData';
import ShopSearch from 'src/sections/Shop/ShopSearch';
// sections
import DeleteConfirmDialog from 'src/sections/common/DeleteConfirm';

export default function Shop() {
  const dispatch = useDispatch();
  const isMobile = useResponsive('down', 'sm');

  const { shops, loading } = useSelector((state) => ({
    shops: state.shop.shops,
    loading: state.shop.loading,
  }));

  const item = {
    heading: 'Are you sure you want to delete this shop? This action cannot be undone.',
  };

  const [openShopCrudDialog, setOpenShopCrudDialog] = useState(false);
  const [shopData, setShopData] = useState({});
  const [openDeleteConfirmDialog, setOpenDeleteConfirmDialog] = useState(false);

  const [searchText, setSearchText] = useState('');
  const [shopPage, setShopPage] = useState(1);
  const total = shops?.total;
  const totalShops = Math.ceil(total / 12);
  // const identifier = 'course';
  // const storedRowsPerPage = localStorage.getItem(`rowsPerPage_${identifier}`);

  const handleCloseDeleteDialog = () => {
    setOpenDeleteConfirmDialog(false);
  };

  const handleDeleteClick = (brand) => {
    setShopData(brand);
    setOpenDeleteConfirmDialog(true);
  };

  const handleDeleteConfirmClick = () => {
    const credentials = {
      dispatch,
      shopId: shopData?._id,
      handleCloseDeleteDialog,
    };
    dispatch(deleteShop(credentials));
  };

  const handleEditClick = (brand) => {
    if (brand) {
      setShopData(brand);
    }
    setOpenShopCrudDialog(true);
  };

  const handleSearch = (value) => {
    setSearchText(value);
  };

  useEffect(() => {
    const credentials = {
      page: shopPage,
      search: searchText,
      dispatch,
      limit: 12,
    };
    dispatch(getAllShops(credentials));
  }, [dispatch, searchText, shopPage]);

  return (
    <>
      {openShopCrudDialog && (
        <ShopCrud
          setOpen={setOpenShopCrudDialog}
          open={openShopCrudDialog}
          isUpdate={shopData}
          setShopData={setShopData}
        />
      )}

      <DeleteConfirmDialog
        onConfirmClick={handleDeleteConfirmClick}
        setOpen={setOpenDeleteConfirmDialog}
        open={openDeleteConfirmDialog}
        item={item}
        loading={loading}
        title={`Shop "${shopData?.name}"`}
      />

      <Container maxWidth="xl">
        <CustomBreadcrumbs
          heading="Shop"
          links={[
            {
              name: 'Dashboard',
              href: paths.dashboard.root,
            },

            {
              name: 'Shop',
            },
          ]}
          action={
            <Stack flexDirection="row" spacing={2}>
              <Button
                onClick={() => setOpenShopCrudDialog(true)}
                variant="contained"
                color="inherit"
                startIcon={<Iconify icon="mingcute:add-line" />}
              >
                New Shop
              </Button>
            </Stack>
          }
          sx={{
            mb: { xs: 3, md: 5 },
          }}
        />
        <Stack
          spacing={3}
          justifyContent="space-between"
          alignItems={{ sm: 'center' }}
          direction={{ xs: 'column', sm: 'row' }}
          mb={3}
        >
          <ShopSearch onSearch={handleSearch} isMobile={isMobile} />
          <Typography variant="subtitle1" pr={5}>
            Total: {shops?.total || 0}
          </Typography>
        </Stack>
        {loading ? (
          <Paper
            sx={{
              textAlign: 'center',
            }}
          >
            <CircularProgress color="inherit" />
          </Paper>
        ) : (
          <ShopData
            shopData={shops}
            handleEditClick={handleEditClick}
            handleDeleteClick={handleDeleteClick}
            page={shopPage}
            setPage={setShopPage}
            total={totalShops}
          />
        )}
      </Container>
    </>
  );
}
