import PropTypes from 'prop-types';

import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';

import Iconify from 'src/components/iconify';

// ----------------------------------------------------------------------

export default function ClothSearch({ onSearch, isMobile }) {
  return (
    <TextField
      onChange={(e) => onSearch(e.target.value)}
      placeholder="Search..."
      sx={{ width: isMobile ? '100%' : '50%' }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Iconify icon="eva:search-fill" sx={{ ml: 1, color: 'text.disabled' }} />
          </InputAdornment>
        ),
      }}
    />
  );
}

ClothSearch.propTypes = {
  onSearch: PropTypes.func,
  isMobile: PropTypes.any,
};
